<template>
  <v-container>
    <PostDescription v-if="type == 'images' && !dialog" :images="images" :post="$props.post" />
    <v-carousel
      v-model="carousel"
      v-if="type == 'imagess' && !dialog"
      :cycle="!dialog"
      height="400"
      hide-delimiter-background
      show-arrows-on-hover
      :hide-delimiters="images.length == 1"
      :show-arrows="images.length > 1"
    >
      <v-carousel-item
        v-for="(image, i) in images"
        :key="i"
        :src="image"
        @click="dialog = true"
      ></v-carousel-item>
    </v-carousel>
    <v-card
      :href="pdfUrl"
      target="_blank"
      class="mx-auto pt-2 text-center"
      max-width="120px"
      v-else-if="type == 'pdf'"
    >
      <v-img
        class="mx-auto"
        width="100"
        height="100"
        contain
        :src="require('@/assets/pdf.png')"
      ></v-img>
      <h4 class="mt-2 py-2 grey lighten-2">{{ pdfFileName }}</h4>
    </v-card>
    <div v-else-if="type == 'video' && video != null" class="black">
      <vue-player
        :src="video"
        style="height: 40vh"
        :poster="thumbnailUrl"
        :playsinline="false"
      ></vue-player>
    </div>
    <v-overlay :value="dialog" opacity="0.8">
      <v-container>
        <v-carousel
          style="width: 90vw"
          v-model="carousel"
          v-if="dialog && type == 'images'"
          hide-delimiter-background
          class="black"
          show-arrows
        >
          <v-carousel-item
            contain
            v-for="(image, i) in images"
            :key="i"
            :src="image"
            @click="dialog = false"
          ></v-carousel-item>
        </v-carousel>
      </v-container>
    </v-overlay>
  </v-container>
</template>

<script>
import storage from "@/firebase/storage";
import vuePlayer from "@algoz098/vue-player";
import PostDescription from "@/components/Trip/Feed/PostDescription";

export default {
  props: ["files", "post"],
  components: { vuePlayer, PostDescription },
  data: () => ({
    images: [],
    carousel: null,
    dialog: false,
    pdfUrl: null,
    fileNames: null,
    thumbnailUrl: null,
    video: null,
  }),
  methods: {
    prepareImages() {
      Promise.all(this.fileNames.map((path) => storage.getDownloadURL(path))).then((urls) => {
        this.images = urls;
      });
    },
    prepareFiles(fileNames) {
      this.fileNames = fileNames;
      if (this.type === "images") {
        this.prepareImages();
      } else if (this.type === "pdf") {
        storage.getDownloadURL(fileNames[0]).then((url) => {
          this.pdfUrl = url;
        });
      } else if (this.type === "video") {
        storage.getDownloadURL(fileNames[0]).then((url) => {
          this.video = url;
        });
        storage.getDownloadURL(`${fileNames[0]}-thumbnail.jpg`).then((url) => {
          this.thumbnailUrl = url;
        });
      }
    },
  },
  computed: {
    type() {
      if (this.fileNames == null || this.fileNames.length === 0) return "none";

      if (this.fileNames[0].includes(".pdf")) {
        return "pdf";
      }
      if (this.fileNames[0].includes(".mp4")) {
        return "video";
      }
      return "images";
    },
    pdfFileName() {
      return this.fileNames[0].split("/").pop().split("-").pop();
    },
  },
  created() {
    this.prepareFiles(this.files ? Object.values(this.files) : null);
  },
  watch: {
    files() {
      const newFiles = this.files ? Object.values(this.files) : null;
      if (this.fileNames !== newFiles) {
        this.prepareFiles(newFiles);
      }
    },
  },
};
</script>
