/* eslint-disable no-param-reassign */
import Vue from 'vue';
import chat from '../firebase/chat';

const chatModule = {
  state: {
    conversations: {},
  },
  mutations: {
    watchedMessage: (state, conversationId) => {
      state.conversations[conversationId].newMessages = 0;
    },
    lastSeenMessage: (state, payload) => {
      state.conversations[payload.conversationId].lastSeenMessage = payload.lastSeenMessage;
    },
    addMessage: (state, payload) => {
      state.conversations[payload.conversationId].messages.push(payload.message);
      if (payload.isNew) {
        state.conversations[payload.conversationId].newMessages += 1;
      }
    },
    oldMessagesAdded: (state, payload) => {
      payload.messages.forEach((message) => {
        state.conversations[payload.conversationId].messages.unshift(message);
      });
    },
    createConversation: (state, conversationId) => {
      Vue.set(state.conversations, conversationId, { newMessages: 0, messages: [] });
    },
  },
  actions: {
    observeMessages(context, conversationId) {
      console.log("store/chat ->observeMessages");
      context.commit('createConversation', conversationId);
      chat.loadNewMessages(context, context.getters.currentTripId, conversationId);
    },
    watchedMessage(context, payload) {
      chat.watchedMessages(context.getters.currentTripId,
        context.rootState.firUser.uid, payload.conversationId, payload.message);
      context.commit("watchedMessage", payload.conversationId);
    },
    sendMessage(context, payload) {
      chat.sendMessage(
        context,
        payload.conversationId,
        payload.message,
        payload.messageId,
      );
    },
    getMoreMessages(context, payload) {
      chat.getMoreMeessages(context, payload.conversationId, payload.lastMessageKey);
    },

  },
  getters: {
    conversations(state) {
      return state.conversations;
    },
  },
};
export default chatModule;
