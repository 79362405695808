<template>
  <div class="primary" style="height: 100%">
    <div>
      <div class="d-none d-sm-flex">
        <v-img
          :src="require('@/assets/dotted_bck.png')"
          style="position: absolute; max-width: 80vw"
        ></v-img>
        <v-img
          :src="require('@/assets/shapes/01.png')"
          style="position: absolute; max-width: 30vw; right: 0; bottom: 0"
        ></v-img>
        <v-img
          :src="require('@/assets/shapes/02.png')"
          style="position: absolute; max-width: 10vw; right: 20%; bottom: 15%"
        ></v-img>
        <v-img
          :src="require('@/assets/shapes/03.png')"
          style="position: absolute; max-width: 30vw; top: 40%; left: 0"
        ></v-img>
        <v-img
          :src="require('@/assets/shapes/06.png')"
          style="position: absolute; max-width: 70vw; bottom: 0; left: 0"
        ></v-img>
        <p class="white--text" style="position: absolute; bottom: 20px; left: 20px">
          Powered by Trip Loop
        </p>
      </div>
      <div
        style="
          margin-top: calc(20vh - 80px);
          margin-left: calc(50% - 372px);
          position: absolute;
          z-index: 1;
          width: 744px;
        "
      >
        <div class="circle d-flex justify-center">
          <div class="mx-auto" style="position: relative; height: auto; width: auto">
            <v-img
              v-if="imageSrc"
              :src="imageSrc"
              style="width: 190px; height: 190px; margin-top: 5px"
              class="rounded-circle"
            />
            <v-icon v-else size="200px" color="grey lighten-2">mdi-account-circle</v-icon>
            <input
              type="file"
              style="display: none"
              ref="image"
              accept="image/*"
              @change="onFilePicked"
            />
            <v-btn
              dark
              icon
              class="primary"
              width="43"
              height="43"
              style="position: absolute; bottom: 0; right: 0"
              @click="$refs.image.click()"
            >
              <v-icon>mdi-camera-plus</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <div style="padding-top: 20vh">
      <v-card max-width="544" class="mx-auto px-4" style="margin-top: 40px; padding-top: 80px">
        <v-card-title class="text-h3 font-weight-black">{{ $t("login.signUp") }}</v-card-title>
        <v-card-text>
          <v-form lazy-validation ref="form">
            <v-text-field
              :label="$t('common.name')"
              v-model="name"
              append-icon="mdi-account"
              :rules="[rules.required]"
            />
            <v-text-field
              :label="$t('common.email')"
              type="email"
              v-model="email"
              append-icon="mdi-email"
              :rules="[rules.required, rules.email]"
            />
            <v-text-field
              :label="$t('login.password')"
              v-model="password"
              :rules="[rules.required, rules.validPassword]"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              :hint="$t('login.password_hint')"
              counter
              @click:append="show = !show"
            />
            <v-menu
              v-if="fullDob"
              v-model="dobMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formattedDob"
                  label="Date of Birth"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  :rules="[rules.requiredDob]"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dob" @input="dobMenu = false"></v-date-picker>
            </v-menu>
            <v-text-field
              v-else
              v-model="yearOfBirth"
              :label="$t('login.year_of_birth')"
              placeholder="yyyy"
              append-icon="mdi-calendar"
              :rules="[rules.requiredDob]"
            ></v-text-field>
            <p v-html="$t('login.privacyText')"></p>
            <v-checkbox color="primary" v-model="acceptPolicy">
              <template v-slot:label>
                <div>
                  {{ $t("login.accept_condition") }}
                </div>
              </template>
            </v-checkbox>
            <p class="red--text" v-if="feedback">{{ $t(feedback) }}</p>
          </v-form>
        </v-card-text>
        <span class="ml-2 text-caption grey--text text--darken-2">
          {{ $t("login.already_account") }}
        </span>
        <v-card-actions>
          <v-btn
            :to="{ name: 'Login', params: $route.params }"
            class="px-11"
            text
            outlined
            style="border-width: 2px"
          >
            {{ $t("login.login") }}
          </v-btn>
          <v-btn
            class="px-9 flex-grow-1"
            color="primary"
            :disabled="!acceptPolicy"
            @click="emailSignIn()"
            :loading="loading"
            >{{ $t("login.signUp") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import login from "@/firebase/login";
import database from "@/firebase/database";
import moment from "moment-timezone";
import storage from "@/firebase/storage";
import resizeImage from "@/plugins/image-resize.js";
import filters from "../../filters";

export default {
  name: "SignUp",
  data() {
    return {
      name: "",
      acceptPolicy: false,
      email: "",
      password: "",
      yearOfBirth: null,
      show: false,
      dob: null,
      fullDob: false,
      dobMenu: false,
      imageSrc: null,
      imageFile: null,
      rules: {
        required: (value) => !!value || this.$t("common.required_field"),
        requiredDob: (value) => !!value || this.$t("login.dob_not_entered"),
        validPassword: (value) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          /^(?=.*[A-Z])(?=.*\d)[!-}]{8,}$/.test(value) || this.$t("login.password_hint"),
        email: (text) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          /[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}/.test(text) ||
          this.$t("login.errors.email_not_entered"),
      },
      loading: false,
      feedback: null,
    };
  },
  created() {
    const params =
      Object.keys(this.$route.params).length !== 0 ? this.$route.params : this.$route.query;
    this.email = params.email || "";
    console.log("login created");
    console.log(this.$route.params);

    if (params.lang) {
      filters.updateLocale(params.lang);
      this.$i18n.locale = params.lang;
      localStorage.setItem("languageSelect", params.lang);
    }
    if (!this.company && params.company) {
      this.$store.dispatch("getCompany", params.company);
    }
  },
  computed: {
    formattedDob() {
      if (!this.dob) return null;
      return this.$options.filters.toDate(new Date(this.dob), "L");
    },
    company() {
      return this.$store.state.trip.company;
    },
    companyImage() {
      if (!this.company) return null;
      return this.$store.getters.getImageUrlById(this.company.id);
    },
  },
  methods: {
    onFilePicked(e) {
      const { files } = e.target;
      console.log("onFilePicked", files);
      if (files[0] !== undefined) {
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", async () => {
          this.imageFile = await resizeImage({ file: files[0] });
          this.imageSrc = fr.result;
        });
      }
    },
    emailSignIn() {
      if (!this.$refs.form.validate()) {
        console.log("errors exist");
        return;
      }
      console.log(this.name, this.email, this.password);

      let dobDate;
      const legalDate = moment(new Date()).subtract(13, "years").toDate();
      console.log("legalDate", legalDate);
      if (this.fullDob) {
        dobDate = new Date(this.dob);
      } else {
        dobDate = new Date(this.yearOfBirth, 11, 31);
        if (dobDate.getFullYear() === legalDate.getFullYear()) {
          console.log("ask fullDob!!!!");
          this.fullDob = true;
          this.dob = `${this.yearOfBirth}-1-1`;
          this.feedback = "login.ask_full_dob";
          return;
        }
      }
      console.log("dobDate", dobDate);

      if (dobDate.getTime() > legalDate.getTime()) {
        this.feedback = "login.below_13";
        return;
      }
      this.feedback = null;
      const dobStr = this.$options.filters.toDate(dobDate, "YYYY-MM-DD");
      this.loading = true;
      login
        .createUser(this.email, this.password)
        .then(async (result) => {
          const { user } = result;
          await database.createNewUser(user.uid, this.name, this.email, dobStr);
          console.log("succes in signin in");
          if (this.imageFile) {
            console.log("should also save image!");
            storage
              .uploadFile(this.imageFile, `profile_pics/${user.uid}/profilePic.jpg`)
              .then(() => {
                const date = new Date();
                console.log("should update profile_pic to", date.getTime());
                database.updateProfile(user.uid, {
                  profile_pic: date.getTime() + 0.1,
                });
                this.loading = false;
              });
          } else {
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log("loginError", error);
          this.loading = false;
          // Handle Errors here.
          const { code: errorCode, message: errorMessage } = error;
          console.log("myError", errorCode, errorMessage);
          switch (errorCode) {
            case "auth/email-already-in-use":
              this.feedback = this.$t("login.errors.email_in_use");
              break;
            case "auth/invalid-email":
              this.feedback = this.$t("login.errors.email_not_entered");
              break;

            case "auth/weak-password":
              this.feedback = this.$t("login.password_hint");
              break;
            case "auth/user-not-found":
            case "auth/wrong-password":
              this.feedback = this.$t("login.errors.wrong_username_password");
              break;
            default:
              this.feedback = error.message;
              break;
          }
        });
    },
  },
  watch: {
    company(company) {
      if (company && company.primary_color) {
        this.$vuetify.theme.themes.light.primary = company.primary_color;
        this.$vuetify.theme.themes.light.secondary = company.secondary_color;
      }
    },
  },
};
</script>

<style scoped>
.circle {
  width: 200px;
  height: 200px;
  background: white;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
</style>
