<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on }">
        <v-btn tile text color="primary" v-on="on" @click="newPollDialog = true">
          <v-icon left>mdi-poll-box</v-icon>{{ $t("feed.NewPollDialog.poll") }}
        </v-btn>
      </template>

      <v-card>
        <div class="primary d-flex justify-space-between align-center">
          <v-card-title class="headline primary white--text" primary-title>{{
            $t("feed.NewPollDialog.newPoll")
          }}</v-card-title>
          <v-btn text class="mr-2" icon @click="closeDialog()">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </div>

        <v-card-text class="pt-2">
          <div class="d-flex flex-row">
            <v-avatar>
              <v-img :src="userProfilePic"></v-img>
            </v-avatar>
            <div class="ml-4 text-h6">{{ userName }}</div>
          </div>
          <v-text-field v-model="question" label="Question"></v-text-field>

          <div class="d-flex flex-row" v-for="(answer, answerIndex) in options" :key="answerIndex">
            <v-text-field
              class="pl-4"
              v-model="options[answerIndex]"
              :label="`Option ${answerIndex + 1}`"
              counter
              maxlength="40"
              prepend-icon="mdi-checkbox-blank-circle-outline"
            ></v-text-field>

            <v-btn
              icon
              class="mt-3"
              v-if="options.length > 2"
              @click="options.splice(answerIndex, 1)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <div v-else class="ma-1" style="width: 28px; height: 10px"></div>
          </div>

          <div class="d-flex flex-row-reverse">
            <v-btn text v-if="options.length < 4" color="primary" @click="options.push('')">
              <v-icon left>mdi-plus</v-icon>{{ $t("common.option") }}
            </v-btn>
          </div>

          <p v-if="error" class="caption error--text">{{ error }}</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" color="primary" @click="createPoll">{{
            $t("common.post")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import firestore from "@/firebase/firestore";

export default {
  data: () => ({
    error: null,

    question: "",
    options: ["", ""],

    dialog: false,
    loading: false,
  }),
  computed: {
    userProfilePic() {
      return this.$store.getters.getImageUrlById(this.$store.state.firUser.uid);
    },
    userName() {
      return this.$store.state.currentUser.name;
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.$emit("closed");
    },
    checkOptions() {
      console.log("checking options");

      if (this.question === "") {
        this.error = this.$t("feed.NewPollDialog.error1");
        return false;
      }

      if (this.options.includes("")) {
        this.error = this.$t("feed.NewPollDialog.error2");
        return false;
      }
      return true;
    },
    adjustForTimezone(date) {
      const timeOffsetInMS = date.getTimezoneOffset() * 60000;
      date.setTime(date.getTime() - timeOffsetInMS);
      return date;
    },
    async createPoll() {
      if (!this.checkOptions()) return;

      console.log("create survey");

      const tripId = this.$store.getters.currentTripId;

      const data = {
        date: new Date(),
        options: this.options,
        question: this.question,
        sender_id: this.$store.state.firUser.uid,
      };

      console.log("finishd creating");
      this.loading = true;
      console.log(data);
      await firestore.writeTrackedDocument(tripId, "polls", data);
      this.loading = false;
      console.log("finishd creating");

      this.closeDialog();
    },
  },
};
</script>
