import Vue from 'vue';
import moment from "moment-timezone";
import i18n from './i18n';

const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
let locale = localStorage.getItem("languageSelect") || "en";

Vue.filter('capitalize', (str) => str.charAt(0).toUpperCase() + str.slice(1));

Vue.filter('toDate', (value, strFormat) => {
  if (!value) return '';
  let date = value;
  if (!(date instanceof Date)) { date = value.toDate(); }
  return moment(date).locale(locale).format(strFormat);
});

Vue.filter('displayTimezone', (value, millis) => {
  const zone = moment.tz.zone(value);
  const offset = zone.utcOffset(millis);
  const abbr = zone.abbr(millis);
  return `${abbr} / GMT${offset > 0 ? "-" : "+"}${Math.abs(offset / 60)}`;
});

Vue.filter('durationStr', (value) => moment.duration(value, "minutes").locale(locale).humanize());

Vue.filter('zonedDate', (value, strFormat, timezone) => {
  if (!value) return '';
  let date = value;
  if (!(date instanceof Date)) { date = value.toDate(); }
  if (date.getTime() === 0) { return 'No Date'; }

  return moment(date).locale(locale).tz(timezone || currentTimeZone).format(strFormat);
});

Vue.filter('strToDate', (value, strFormat) => (!value ?
  '' : moment(value).locale(locale).format(strFormat)));

// Vue.filter('parseLocale', (value, strFormat) => {
//   if (!value) return '';
//   console.log('parsing', value, strFormat);
//   return parse(value, strFormat, new Date(), { locale });
// });

Vue.filter('smartDate', (value) => {
  const date = moment(value);
  const now = moment(new Date());
  if (date.isSame(now.startOf('day'), 'day')) {
    return moment(value).locale(locale).format('LT');
  }
  if (date.clone().subtract(1, 'days').startOf('day').isSame(now, 'day')) {
    return i18n.t('common.time.yesterday');
  }
  if (date.isSame(now, 'week')) {
    return moment(value).locale(locale).format('dddd');
  }
  return moment(value).locale(locale).format('lll');
});

Vue.filter("timeDistanceFromNow", (date, now) => moment.duration(moment(date).diff(moment(now || new Date()))).locale(locale).humanize(true));

const updateLocale = (newLocale) => {
  locale = newLocale || "en";
};

export default {
  updateLocale,
};
