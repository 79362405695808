<template>
  <v-container class="d-flex flex-column">
    <div v-if="tripInfo" class="flex-grow-0 flex-shrink-0">
      <h1>
        <b>{{ tripInfo.name }}</b>
      </h1>
      <div class="text-h6">{{ $t("navbar.menu.itinerary") }}</div>
      <div class="text-subtitle-1 primary--text">
        {{
          $t("itinerary.dates", {
            dates: `${$options.filters.zonedDate(tripInfo.start_date, "l", "UTC")} -
        ${$options.filters.zonedDate(tripInfo.end_date, "l", "UTC")}`,
          })
        }}
      </div>
      <div class="overflow-y-auto d-flex scrolledDates">
        <div class="pt-0 pb-8 mx-2">
          <v-chip-group mandatory active-class="primary" v-model="tab">
            <v-chip v-for="day in itineraryDates" :key="day">
              {{ customFormat(day) }}
            </v-chip>
          </v-chip-group>
        </div>
      </div>
    </div>
    <div class="text-h6 primary--text" v-if="itineraryDates[tab] !== undefined">
      {{
        groupedItinerary[itineraryDates[tab]][0].sortingDate
          | zonedDate("dddd, LL", groupedItinerary[itineraryDates[tab]][0].display_timezone)
          | capitalize
      }}
    </div>
    <v-tabs-items style="overflow-y: auto" class="flex-grow-1" v-model="tab">
      <v-tab-item v-for="day in itineraryDates" :key="day">
        <v-row class="ma-0">
          <v-col cols="12" md="12" lg="10" xl="8">
            <template v-for="activity in groupedItinerary[day]">
              <EventCard
                v-if="activity.collection == 'events'"
                :event="activity"
                :key="activity.id"
              />
              <SurveyCard v-else-if="activity.questions" :survey="activity" :key="activity.id" />
              <TravelCard
                v-if="activity.collection == 'travels'"
                :travel="activity"
                :key="activity.id"
              />
            </template>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import EventCard from "./Cards/EventCard";
import SurveyCard from "./Cards/SurveyCard";
import TravelCard from "./Cards/TravelCard";
// import HotelCard from "./Cards/HotelCard";

export default {
  name: "Itinerary",
  // components: { EventCard, SurveyCard, TravelCard, HotelCard },
  components: { EventCard, SurveyCard, TravelCard },
  props: ["tripId"],
  data() {
    return {
      tab: null,
    };
  },
  computed: {
    tripInfo() {
      return this.$store.state.trip.trip;
    },
    groupedItinerary() {
      return _.groupBy(
        this.itinerary,
        (activity) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          moment(activity.sortingDate)
            .tz(
              activity.remote_tz != null
                ? moment.tz.guess()
                : activity.display_timezone || activity.time_zone,
            )
            .startOf("day")
            .format("YYYY-MM-DD"),
        // eslint-disable-next-line function-paren-newline
      );
    },
    itineraryDates() {
      return Object.keys(this.groupedItinerary);
    },

    itinerary() {
      return this.$store.getters.sortedItinerary;
    },
    inputActivities() {
      return this.$store.getters.inputActivities;
    },
  },
  methods: {
    customFormat(dateStr) {
      const date = moment(dateStr);
      let formatted = this.$options.filters.strToDate(date, "dddd, LL");
      const year = date.toDate().getFullYear();
      formatted = formatted.replace(year, "");

      if (formatted[formatted.length - 1] === " ") {
        formatted = formatted.slice(0, -1);
      }
      if (formatted[formatted.length - 1] === ",") {
        formatted = formatted.slice(0, -1);
      }
      return this.$options.filters.capitalize(formatted);
    },
  },
};
</script>
<style scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
/* .scrolledDates::-webkit-scrollbar {
  display: none;
} */
</style>
