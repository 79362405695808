// import { v4 as uuidv4 } from 'uuid';

const notify = (title, body, id) => {
  // Check for notification compatibility.

  if (!('Notification' in window)) {
    // If the browser version is unsupported, remain silent.
    return;
  }
  if (!id) return;
  // Log current permission level
  console.log(Notification.permission);
  // If the user has not been asked to grant or deny notifications
  // from this domain...
  if (Notification.permission === 'default') {
    Notification.requestPermission(() => {
      // ...callback this function once a permission level has been set.
      console.log("resending notification", title, body, id);
      notify(title, body, id);
    });
    // If the user has granted permission for this domain to send notifications...
  } else if (Notification.permission === 'granted') {
    console.log("sending notification", title, body, id);
    const n = new Notification(
      title,
      {
        body,
        // ...prevent duplicate notifications
        renotify: true,
        tag: id, // uuidv4()
      },
    );
    // Remove the notification from Notification Center when clicked.
    n.onclick = () => {
      this.close();
    };
    // Callback function when the notification is closed.
    n.onclose = () => {
      console.log('Notification closed');
    };
    // If the user does not want notifications to come from this domain...
  } else if (Notification.permission === 'denied') {
    // ...remain silent.
    // return;
  }
};

export default notify;
