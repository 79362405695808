<template>
  <v-container fluid class="ma-2">
    <GroupConversation
      :conversation="detailConversation"
      :newUsers="newGroupUsers"
      @create="createConversation"
      @close="closeDetail"
      @update="updateConversation"
    />
    <v-card>
      <!-- <v-row no-gutters>
        <v-col sm="4"> -->
      <v-navigation-drawer
        absolute
        :permanent="!$vuetify.breakpoint.mobile"
        :temporary="$vuetify.breakpoint.mobile"
        v-model="drawer"
        :style="$vuetify.breakpoint.mobile ? 'width: 100%' : 'width: 400px'"
      >
        <Chats
          :value="loadedConversation"
          @conversation="goToConversation"
          @newChat="newChat"
        ></Chats>
      </v-navigation-drawer>
      <!-- </v-col>
        <v-col sm="8" style="position: relative; height: 80vh"> -->
      <div
        class="d-flex flex-column"
        :style="
          'height: calc(100vh - 160px);' + (!$vuetify.breakpoint.mobile ? 'margin-left:400px;' : '')
        "
      >
        <div class="d-flex flex-row justify-space-between align-center flex-shrink-0">
          <v-app-bar-nav-icon
            class="ml-2 hidden-lg-and-up"
            @click="drawer = !drawer"
          ></v-app-bar-nav-icon>
          <div class=".d-none hidden-md-and-down"></div>
          <ConversationDetails
            v-if="loadedConversation"
            @details="showDetails"
            :conversationId="loadedConversation"
          />
          <NewConversationUsers @users="newChat" />
        </div>
        <div class="chat-container flex-grow-1" v-on:scroll="onScroll" ref="chatContainer">
          <message
            :messages="messages"
            :isGroupChat="isGroupChat"
            @imageLoad="scrollToEnd"
          ></message>
        </div>
        <VEmojiPicker
          ref="emojiPicker"
          class="flex-shrink-0"
          v-show="emojiPanel"
          @select="addEmojiToMessage"
          :emojisByRow="emojisByRow"
          style="width: 100%; height: 450px"
        />

        <div v-if="loadedConversation" class="typer flex-shrink-0 d-flex flex-row align-end pb-2">
          <input
            type="file"
            style="display: none"
            ref="image"
            accept="image/png, image/jpeg, image/jpg"
            @change="onImagePicked"
          />

          <v-btn @click="$refs.image.click()" class="" color="primary" icon
            ><v-icon>mdi-image</v-icon></v-btn
          >
          <v-btn icon color="primary" @click="toggleEmojiPanel">
            <v-icon>mdi-emoticon-outline</v-icon>
          </v-btn>
          <v-textarea
            dense
            :label="this.$t('chat.placeholder')"
            auto-grow
            ref="inputMessage"
            v-model="content"
            rows="1"
            hide-details
            class="textarea flex-grow-1 ml-1 pb-1 pt-3"
            flat
            @keydown="handleKeyDown"
          ></v-textarea>
          <v-btn class="mr-4" icon @click="sendMessage" :disabled="!content">
            <v-icon color="primary">mdi-send</v-icon>
          </v-btn>
        </div>
      </div>
      <!-- </v-col>
      </v-row> -->
    </v-card>
  </v-container>
</template>

<script>
import VEmojiPicker from "v-emoji-picker";
import database from "@/firebase/database";
import firestore from "@/firebase/firestore";
import storage from "@/firebase/storage";
import resizeImage from "@/plugins/image-resize.js";
import Message from "./parts/Message";
import Chats from "./parts/Chats";
import NewConversationUsers from "./parts/NewConversationUsers";
import GroupConversation from "./parts/GroupConversation";
import ConversationDetails from "./parts/ConversationDetails";

export default {
  name: "Chat",
  components: {
    message: Message,
    NewConversationUsers,
    ConversationDetails,
    GroupConversation,
    VEmojiPicker: VEmojiPicker.VEmojiPicker,
    Chats,
  },
  data() {
    return {
      content: "",
      drawer: true,
      // chatMessages: [],
      emojisByRow: 10,
      loadedConversation: null,
      emojiPanel: false,
      currentRef: {},
      loading: false,
      totalChatHeight: 0,
      scrollLatestMessage: null,
      scrollOldestMessage: null,
      newGroupUsers: null,
      detailConversation: null,
    };
  },

  computed: {
    messages() {
      const { conversation } = this;
      if (!conversation) return [];
      return conversation.messages;
    },
    isGroupChat() {
      if (!this.loadedConversation) return false;
      return this.$store.state.trip.chats[this.loadedConversation].name != null;
    },
    conversation() {
      if (!this.loadedConversation) return null;
      return this.$store.state.trip.chat.conversations[this.loadedConversation];
    },
    userId() {
      return this.$store.state.firUser.uid;
    },
  },

  methods: {
    newChat(partners) {
      this.drawer = false;
      console.log("create chat!!");
      console.log(partners);
      if (partners.length === 1) {
        console.log("single chat");

        const sameConversation = this.$store.getters.conversationWithPartner(partners[0]);
        if (sameConversation) {
          this.goToConversation(sameConversation.id);
          return;
        }

        const meId = this.$store.state.firUser.uid;
        const members = [meId, partners[0]];
        this.createConversation({ users: members });
      } else {
        console.log("multi-chat");
        this.newGroupUsers = partners;
      }
    },
    async createConversation(data) {
      console.log("creating conversation");
      const users = {};
      const payload = { ...data };
      payload.users.forEach((user) => {
        users[user] = true;
      });
      // eslint-disable-next-line no-param-reassign
      payload.users = users;

      const tripId = this.$store.getters.currentTripId;
      console.log("writing to chats");
      const response = await firestore.writeDocument(tripId, "chats", {
        ...payload,
        created: new Date(),
      });
      const newChatId = response.id;
      console.log("delay for conversation to create ");
      setTimeout(() => {
        console.log("timeout:-> go to conversation");
        this.goToConversation(newChatId);
        this.newGroupUsers = null;
      }, 500);
    },
    async updateConversation(data) {
      const users = {};
      const payload = { ...data };
      payload.users.forEach((user) => {
        users[user] = true;
      });
      payload.users = users;

      const tripId = this.$store.getters.currentTripId;

      await firestore.writeDocument(tripId, "chats", payload, this.detailConversation);
      this.closeDetail();
    },
    showDetails() {
      this.detailConversation = this.loadedConversation;
    },
    closeDetail() {
      this.newGroupUsers = null;
      this.detailConversation = null;
    },
    goToConversation(conversationId) {
      this.drawer = false;
      console.log(`[Chat] should go to conversation ${conversationId}`);

      this.totalChatHeight = this.$refs.chatContainer.scrollHeight;
      this.loading = false;
      this.loadedConversation = conversationId;
      if (this.messages[0]) {
        this.watchedMessages();
        console.log("this conversation has messages, loding more perhaps? ");
        this.$store.dispatch("getMoreMessages", {
          conversationId,
          lastMessageKey: this.messages[0].id,
        });
      }
    },
    watchedMessages() {
      if (this.timeOut) clearTimeout(this.timeOut);
      this.timeOut = setTimeout(this.sendWatchesMessages, 500);
    },
    sendWatchesMessages() {
      if (this.messages.length === 0) return;
      const lastMessage = this.messages[this.messages.length - 1];
      const { lastSeenMessage } =
        this.$store.state.trip.chat.conversations[this.loadedConversation];

      if (lastSeenMessage !== lastMessage.id) {
        this.$store.dispatch("watchedMessage", {
          conversationId: this.loadedConversation,
          message: lastMessage.id,
        });
      }
    },
    async onImagePicked(e) {
      const { files } = e.target;
      if (!files || files.length === 0 || !this.loadedConversation) return;
      const file = files[0];

      const resizedImageFile = await resizeImage({ file });

      const messageKey = database.childByAutoId();
      const extension = file.name.split(".").pop();
      const tripId = this.$store.getters.currentTripId;
      const path = `groups/${tripId}/chats/${this.loadedConversation}/${messageKey}.${extension}`;

      await storage.uploadFile(resizedImageFile, path);

      const message = {
        sender: this.userId,
        timestamp: new Date().getTime(),
        image_path: path,
      };
      this.$store.dispatch("sendMessage", {
        conversationId: this.loadedConversation,
        message,
        messageId: messageKey,
      });
    },
    handleKeyDown(event) {
      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault(); // Prevents a newline from being added in the textarea
        this.sendMessage();
      }
    },
    sendMessage() {
      const trimmedContent = this.content.replace(/\n+$/, "").trim();

      if (trimmedContent === "") return;
      this.content = trimmedContent;
      if (this.loadedConversation) {
        const message = {
          sender: this.userId,
          timestamp: new Date().getTime(),
          text: this.content,
        };

        this.$store.dispatch("sendMessage", {
          conversationId: this.loadedConversation,
          message,
        });

        this.content = "";
      }
    },
    onScroll() {
      const scrollValue = this.$refs.chatContainer.scrollTop;
      if (scrollValue < 100 && !this.loading) {
        this.totalChatHeight = this.$refs.chatContainer.scrollHeight;
        this.loading = true;
        const chatID = this.loadedConversation;
        const currentTopMessage = this.messages[0];
        if (currentTopMessage === undefined) {
          this.loading = false;
          return;
        }
        console.log("[scrolleed] fetching new messages");
        this.$store.dispatch("getMoreMessages", {
          conversationId: chatID,
          lastMessageKey: currentTopMessage.id,
        });
      }
    },
    // processMessage (message) {
    //   /*eslint-disable */
    //   var imageRegex = /([^\s\']+).(?:jpg|jpeg|gif|png)/i
    //   /*eslint-enable */
    //   if (imageRegex.test(message.content)) {
    //     message.image = imageRegex.exec(message.content)[0]
    //   }
    //   var emojiRegex = /([\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-
    // \u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-
    // \u{1f1ff}\u{1f191}-\u{1f251}\u{2934}-\u{1f18e}])/gu
    //   if (emojiRegex.test(message.content)) {
    //     message.content = message.content.replace(emojiRegex, '<span class="emoji">$1</span>')
    //   }
    //   return message
    // },

    scrollToEnd() {
      this.$nextTick(() => {
        const container = this.$el.querySelector(".chat-container");
        container.scrollTop = container.scrollHeight;
      });
    },
    scrollTo() {
      this.$nextTick(() => {
        const currentHeight = this.$refs.chatContainer.scrollHeight;
        const difference = currentHeight - this.totalChatHeight;
        const container = this.$el.querySelector(".chat-container");
        container.scrollTop = difference;
      });
    },
    addEmojiToMessage(emoji) {
      console.log(emoji);
      this.content += emoji.data;
      this.$refs.inputMessage.focus();
    },
    toggleEmojiPanel() {
      this.emojiPanel = !this.emojiPanel;
      this.emojisByRow = Math.floor(this.$refs.chatContainer.clientWidth / 50);
      console.log(this.emojisByRow);
    },
  },
  watch: {
    messages(newMessages) {
      console.log("messages changed");

      if (newMessages.length === 0) return;

      const newLatestMessage = newMessages[newMessages.length - 1].id;
      const newOldestMessage = newMessages[0].id;
      if (newLatestMessage !== this.scrollLatestMessage) {
        this.scrollLatestMessage = newLatestMessage;
        this.scrollToEnd();
        this.sendWatchesMessages();
      } else if (newOldestMessage !== this.scrollOldestMessage) {
        this.scrollOldestMessage = newOldestMessage;
        this.scrollTo();
      }
      this.loading = false;
    },
  },
};
</script>

<style>
.typer {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  bottom: 0;
  /* height: 3.9rem; */
  width: 100%;
  background-color: #fff;
  box-shadow: 0 -5px 10px -5px rgba(0, 0, 0, 0.2);
}

.chat-container {
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  /* height: calc(82vh - 9.5rem); */
  overflow-y: auto;
  padding: 10px;
  background-color: #f2f2f2;
}

.message {
  margin-bottom: 3px;
}

.message.own {
  text-align: right;
}

.message.own .content {
  background-color: var(--v-primary-base);
  color: white;
}

.chat-container .username {
  font-size: 15px;
  /* font-weight: bold; */
}

.chat-container .content {
  padding: 8px;
  background-color: #e1e1e1;
  border-radius: 10px;
  display: inline-block;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  word-wrap: break-word;
}

/* @media (max-width: 480px) {
  .chat-container .content {
    max-width: 60%;
  }
} */

/* textarea::placeholder {
  height: 30px;
  line-height: 45px;
} */
</style>
