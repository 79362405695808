// eslint-disable-next-line import/no-unresolved
import { v4 as uuidv4 } from 'uuid';
import notify from "./notify";

let hasRequestedPermissions = false;
let canNotify = false;

const checkPermissions = () => {
  if (!hasRequestedPermissions) {
    hasRequestedPermissions = true;
    notify();
  }
};

const notificationsEnabled = (enabled) => {
  canNotify = enabled;
};

const newMessageNotification = (context, conversationId, message) => {
  let conversationName = "";
  const conversation = context.rootState.trip.chats[conversationId];
  if (conversation.name !== undefined) {
    conversationName = ` @ ${conversation.name}`;
  }

  const userName = context.getters.tripUserShortName(message.sender);
  let messageText = "";

  if (message.text) {
    messageText = message.text;
  } else if (message.image_path) {
    messageText = "Image";
  } else if (message.video_path) {
    messageText = "Video";
  } else if (message.audio_path) {
    messageText = "Voice Message";
  }
  console.log(userName + conversationName, messageText, conversationId);
  if (canNotify) notify(userName + conversationName, messageText, message.id);
};

const newPostNotification = (post) => {
  const files = post.files || {};
  const filesKeys = Object.keys(files);
  let fileType;
  if (filesKeys.length > 0) {
    const firstFile = files[filesKeys[0]];
    if (firstFile.includes("pdf")) {
      fileType = "pdf";
    } else if (firstFile.includes("mp4")) {
      fileType = "video";
    } else {
      fileType = "image";
    }
  }
  if (canNotify) notify("New Post", post.text || fileType, uuidv4());
};

export default {
  checkPermissions,
  newMessageNotification,
  notificationsEnabled,
  newPostNotification,
};
