import firebase from 'firebase/app';
import 'firebase/auth';

const waitForLogin = ((callback) => {
  console.log('login.js->waitForLogin->waiting for user');
  firebase.auth().onAuthStateChanged(callback);
});

const sendPasswordResetEmail = ((emailAddress) => {
  const auth = firebase.auth();
  const settings = {
    url: "https://app.triploop.com/password_reset",
    // url: "https://dev.triploop.com/password_reset",
    handleCodeInApp: true,
  };
  return auth.sendPasswordResetEmail(emailAddress, settings);
  // .then(function () {
  //     console.log('success');
  //     // Email sent.
  // })
  // .catch(function (error) {
  //     console.log(error)
  //     // An error happened.
  // });
});

const deleteUser = (() => firebase.auth().currentUser.delete());

const emailSignIn = ((email, password) => firebase.auth()
  .signInWithEmailAndPassword(email, password));

const createUser = ((email, password) => firebase.auth()
  .createUserWithEmailAndPassword(email, password));

export default {
  emailSignIn,
  waitForLogin,
  createUser,
  sendPasswordResetEmail,
  deleteUser,
};
