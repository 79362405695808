import firebase from 'firebase/app';
import "firebase/performance";
import "firebase/analytics";

const devConfig = {
  apiKey: "AIzaSyCsDEr-hjDrlT5hcgOjB-i12uLto9LGyH0",
  authDomain: "triploop-dev.firebaseapp.com",
  databaseURL: "https://triploop-dev.firebaseio.com",
  projectId: "triploop-dev",
  storageBucket: "triploop-dev.appspot.com",
  messagingSenderId: "68583121541",
  appId: "1:68583121541:web:aa454fdcb8851f2bc37fec",
  measurementId: "G-QE14GQE3YJ",
};

const prodConfig = {
  apiKey: "AIzaSyAg5L1YvRoH4UJ8p-o8a4FH7BGzvfWVYPA",
  authDomain: "triploop-12907.firebaseapp.com",
  databaseURL: "https://triploop-12907.firebaseio.com",
  projectId: "triploop-12907",
  storageBucket: "triploop-12907.appspot.com",
  messagingSenderId: "952406852926",
  appId: "1:952406852926:web:65b25521b26b7847",
  measurementId: "G-8YQFDYV8NT",
};

// Initialize Firebase

if (process.env.NODE_ENV === 'development') {
  firebase.initializeApp(devConfig);
  // firebase.initializeApp(prodConfig);
} else {
  console.log(window.location.origin);

  if (window.location.origin.includes('dev')) {
    firebase.initializeApp(devConfig);
  } else {
    firebase.initializeApp(prodConfig);
    // eslint-disable-next-line
    const performance = firebase.performance()
    // eslint-disable-next-line
    const analytics = firebase.analytics()
  }
}
