<template>
  <div style="position: relative; height: 80vh">
    <div class="d-flex flex-column" style="height: 100%">
      <v-list outlined class="py-0">
        <ConversationCell :chat="myProfile" />
        <v-list-item>
          <v-list-item-content>
            <v-text-field
              v-model="filter"
              placeholder="Search"
              filled
              rounded
              dense
              clearable
              hide-details
            ></v-text-field>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item-group :value="value" color="primary">
          <template v-if="groupChat && !isSearching">
            <ConversationCell :chat="groupChat" :pinned="true" @goToChat="goToChat" />
            <v-divider></v-divider>
          </template>
          <template v-if="helpChat && !isSearching">
            <ConversationCell :chat="helpChat" :pinned="true" @goToChat="goToHelpChat" />
            <v-divider></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
      <div class="scrollable flex-grow-1">
        <v-list outlined class="py-0" style="height: 100%">
          <v-list-item-group :value="value" color="primary">
            <template v-for="chat in chats">
              <ConversationCell :chat="chat" :key="chat.id" @goToChat="goToChat" />
              <v-divider :key="`${chat.id}_div`"></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import ConversationCell from "./ConversationCell";

export default {
  components: { ConversationCell },
  props: {
    value: {
      type: String,
    },
  },
  data: () => ({
    selected: null,
    filter: null,
  }),
  methods: {
    goToChat(conversationId) {
      console.log(`go to chat ${conversationId}`);
      this.$emit("conversation", conversationId);
      // this.$emit("input", conversationId);
    },
    goToHelpChat(conversationId) {
      if (conversationId) {
        this.goToChat(conversationId);
      } else {
        console.log("create help chat");
        this.$emit("newChat", [this.$store.state.trip.trip.contact]);
      }
    },
    conversationIndex(id) {
      return this.chats.findIndex((chat) => chat.id === id);
    },
    mapChat(chat) {
      const item = { id: chat.id };

      if (chat.name) {
        item.name = chat.name;
        if (chat.image_path) {
          item.image = chat.id;
        }
      } else {
        const partnerId = Object.keys(chat.users).find((a) => a !== this.meId);
        item.name = this.$store.getters.tripUserShortName(partnerId);
        item.image = partnerId;
      }
      if (chat.id) {
        const conversation = this.$store.state.trip.chat.conversations[chat.id];
        item.new = conversation.newMessages;
        const messages = conversation.messages.filter((m) => m.deleted !== true);

        if (messages.length > 0) {
          const message = messages[messages.length - 1];
          const senderName = this.$store.getters.tripUserShortName(message.sender);

          let { text } = message;
          if (message.video_path) {
            text = "video";
          } else if (message.image_path) {
            text = "image";
          } else if (message.audio_path) {
            text = "audio";
          }

          item.subtitle = (chat.name && !message.isMine ? `${senderName}: ` : "") + text;
          const date = new Date(message.timestamp);
          item.time = date;
        }
      }
      return item;
    },
  },
  computed: {
    isSearching() {
      return this.filter && this.filter !== "";
    },
    myProfile() {
      return {
        name: this.$store.getters.tripUserShortName(this.meId),
        image: this.meId,
      };
    },
    groupChat() {
      const {
        trip: { id: tripId },
        chats,
      } = this.$store.state.trip;
      /// ?? fallback is for backward compatibility
      const chat = chats[`chat_${tripId}`] ?? chats[tripId];
      if (!chat) return null;
      const chatInfo = this.mapChat(chat);
      if (!this.$store.getters.getImageUrlById(chatInfo.image)) {
        chatInfo.image = this.$store.state.trip.company.id;
        chatInfo.scale = true;
      }
      return chatInfo;
    },
    helpChat() {
      const { trip } = this.$store.state.trip;
      const contactId = trip.contact;
      const tripId = trip.id;
      if (!contactId || this.meId === contactId) return null;

      console.log("searching chat with users", contactId, this.meId);
      const helpChat = Object.values(this.$store.state.trip.chats).find((chat) => {
        const users = Object.keys(chat.users);
        return chat.name == null && users.includes(this.meId) && users.includes(contactId);
      });
      if (helpChat) {
        return this.mapChat(helpChat);
      }
      const users = {
        [contactId]: true,
      };
      return this.mapChat({ users, newMessages: 0 });
    },
    meId() {
      return this.$store.state.firUser.uid;
    },
    chats() {
      const { trip } = this.$store.state.trip;
      const contactId = trip.contact;
      const tripId = trip.id;

      return (
        Object.values(this.$store.state.trip.chats)
          .filter((chat) => {
            if (this.isSearching) return true;
            const isGroupChat = chat.id === tripId || chat.id === `chat_${tripId}`;
            let isHelpChat = false;

            if (contactId && this.meId !== contactId) {
              const users = Object.keys(chat.users);
              isHelpChat =
                chat.name == null && users.includes(this.meId) && users.includes(contactId);
            }

            return !isGroupChat && !isHelpChat;
          })
          .map(this.mapChat)
          // eslint-disable-next-line no-confusing-arrow
          .filter(
            // eslint-disable-next-line no-confusing-arrow
            (c) =>
              // eslint-disable-next-line implicit-arrow-linebreak
              this.isSearching ? c.name.toLowerCase().includes(this.filter.toLowerCase()) : true,
            // eslint-disable-next-line function-paren-newline
          )
          .sort((a, b) => {
            const time1 = a.time || new Date("1970");
            const time2 = b.time || new Date("1970");
            return time2 - time1;
          })
      );
    },
  },
};
</script>

<style  scoped>
.scrollable {
  display: flex;
  flex-flow: column;
  overflow-y: auto;
}
</style>
