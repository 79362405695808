<template>
  <v-card>
    <v-card-text>
      <div class="d-flex justify-space-between align-center">
        <div class="d-flex align-center">
          <v-avatar class="ml-2" size="36px">
            <img alt="Avatar" :src="post.userPic" />
          </v-avatar>
          <h4 class="ml-2">{{ post.userName }}</h4>
          <!-- <strong class="ml-2" v-html="post.userName">{{post.userName}}</strong> -->
        </div>
        <div>
          <span>{{ post.date.toDate() | timeDistanceFromNow }}</span>
          <v-menu offset-y v-if="canDelete">
            <template v-slot:activator="{ on }">
              <v-btn text icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="canDelete" @click="deletePost()">
                <v-list-item-title>{{ $t("common.delete") }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <p class="mt-2 mb-0" style="white-space: pre-line" v-html="formatUrls(post.text)"></p>
      <Attachment class="pb-0" :files="post.files" :post="post" />

      <!--      <div v-if="post.link_metadata" :href="post.link_metadata.url" target="_blank">-->
      <v-card
        v-if="post.link_metadata"
        :href="post.link_metadata.url"
        target="_blank"
        @click="logLinkClick"
      >
        <v-img
          v-if="post.link_metadata.image"
          :src="post.link_metadata.image"
          height="200px"
        ></v-img>

        <v-card-title>
          {{ post.link_metadata.title }}
        </v-card-title>

        <v-card-subtitle>
          {{ post.link_metadata.description }}
        </v-card-subtitle>
      </v-card>
      <div v-if="canComment" class="mt-1 d-flex align-center">
        <div>
          <v-tooltip class="pa-0 ma-0" top :disabled="likes.length == 0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon @click="toggleLike()">
                <v-icon v-if="liked" color="primary">mdi-thumb-up</v-icon>
                <v-icon v-else color="primary">mdi-thumb-up-outline</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <template v-for="(userId, index) in likes">
                <v-list-item :key="userId">
                  <v-list-item-avatar size="30">
                    <v-img :src="userPic(userId)"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="userName(userId)"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="index < likes.length - 1" :key="`${userId}_div`"></v-divider>
              </template>
            </v-list>
          </v-tooltip>
          <div>
            {{
              $tc("feed.postLikes", likes.length, {
                likeCounts: likes.length,
              })
            }}
          </div>
        </div>
        <div class="ml-5">
          <v-icon class="mt-2 mb-1" color="primary">mdi-message</v-icon>
          <div>
            {{
              $tc("feed.postComments", commentsCount, {
                commentsCount: commentsCount,
              })
            }}
          </div>
        </div>
      </div>
      <!-- <span>{{ likesText }}</span> -->
      <div class="d-flex flex-row"></div>
      <PostComments v-if="canComment" :postId="post.id" />
    </v-card-text>
  </v-card>
</template>

<script>
import firestore from "@/firebase/firestore";
import Attachment from "./Attachment";
import PostComments from "./PostComments";

export default {
  props: ["post"],
  components: { Attachment, PostComments },
  data() {
    return {};
  },
  methods: {
    deletePost() {
      const tripId = this.$store.getters.currentTripId;
      firestore.writeTrackedDocument(tripId, "feed", {}, this.post.id, true);
    },
    userPic(userId) {
      return (
        this.$store.getters.getImageUrlById(userId) || require("@/assets/profile_pic_empty.png")
      );
    },
    userName(userId) {
      return this.$store.getters.getTripUserById(userId).name;
    },
    logLinkClick() {
      const tripId = this.$store.getters.currentTripId;

      const clicks = Object.keys(this.post.url_clicks || {});
      if (clicks.includes(this.userId)) return;

      firestore.writeTrackedDocument(
        tripId,
        "feed",
        {
          [`url_clicks.${this.userId}`]: true,
        },
        this.post.id,
      );
    },
    toggleLike() {
      const tripId = this.$store.getters.currentTripId;
      const path = `likes.${this.userId}`;
      const update = {};

      if (this.liked) {
        console.log("set unlikeed");
        update[path] = firestore.fieldDelete();
      } else {
        console.log("set likeed");
        update[path] = true;
      }
      console.log(update);
      firestore.writeTrackedDocument(tripId, "feed", update, this.post.id);
    },
    formatUrls(text) {
      if (this.validURL(text) && this.post.link_metadata) {
        return "";
      }
      const URLMatcher =
        /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim;
      return (text || "").replace(
        URLMatcher,
        (match) => `<a href="${match}" target="_blank">${match}</a>`,
      );
    },
    validURL(str) {
      const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i",
      ); // fragment locator
      return !!pattern.test(str);
    },
    // agoTime(date) {
    //   const now = new Date();
    //   return `${this.$t("common.time.ago", {
    //     time: this.$options.filters.timeDistanceFromNow(date, now),
    //   })}`;
    // },
  },
  computed: {
    canComment() {
      if (this.post.allow_comments === undefined) return true;
      return this.post.allow_comments;
    },
    canDelete() {
      const meId = this.userId;
      return this.post.sender_id === meId || this.$store.state.trip.trip.user_ids[meId];
    },
    liked() {
      if (!this.post.likes) return false;
      return Object.keys(this.post.likes).includes(this.userId);
    },
    userId() {
      return this.$store.state.firUser.uid;
    },
    likes() {
      return Object.keys(this.post.likes || {});
    },
    commentsCount() {
      if (!this.post.comment_ids) return 0;
      return Object.keys(this.post.comment_ids).length;
    },
  },
};
</script>
