<template>
  <v-container>
    <a
      href="https://www.iubenda.com/privacy-policy/79904406"
      class="iubenda-white no-brand iubenda-embed iub-legal-only iub-body-embed"
      title="Privacy Policy"
      >Privacy Policy</a
    >
  </v-container>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  data() {
    return {};
  },
  mounted() {
    console.log("mountted");

    function loader(d) {
      const s = d.createElement("script");
      const tag = d.getElementsByTagName("script")[0];
      s.src = "https://cdn.iubenda.com/iubenda.js";
      tag.parentNode.insertBefore(s, tag);
    }
    loader(document);
  },
};
</script>
