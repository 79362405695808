<template>
  <v-card v-if="travel.origin != null" class="my-2" style="background-color: #fefefe">
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="4" lg="4" xl="3">
          <v-img :aspect-ratio="5 / 3" :src="image" style="border-radius: 7px; margin-bottom: 13px">
            <div :class="{ 'badge-ribbon': true }" style="--ribbon-color: #5297e7">
              <v-icon color="white" style="z-index: 2; padding-left: 8px; padding-top: 8px">
                mdi-bus
              </v-icon>
            </div>
          </v-img>
        </v-col>
        <v-col cols="12" sm="8" lg="8" xl="9">
          <div class="d-flex flex-column">
            <div class="d-flex align-center justify-space-between">
              <div class="text-h6" style="padding: 0">
                <b>{{ travel.name }}</b>
              </div>

              <div
                class="d-flex justify-center align-center secondary text-center white--text"
                style="border-radius: 50%; width: 54px; height: 54px"
              >
                <div class="px-1 text-caption" style="line-height: 100%">
                  {{
                    ((travel.destin.date.seconds - travel.origin.date.seconds) / 60) | durationStr
                  }}
                </div>
              </div>
            </div>
            <v-row class="pa-0 ma-0">
              <v-col cols="12" md="6">
                <div class="py-1">
                  <v-icon small color="primary">mdi-clock</v-icon>
                  <b class="primary--text">
                    {{ travel.origin.date | zonedDate("LT", travel.origin.time_zone) }}
                    &nbsp;- {{ travel.destin.date | zonedDate("LT", travel.destin.time_zone) }}
                  </b>
                </div>

                <div class="py-1">
                  <v-icon small color="primary">mdi-account-group</v-icon>
                  {{ attending }}
                </div>

                <!-- <div>
                  <v-dialog
                    v-if="event.description != ''"
                    v-model="descriptionDialog"
                    width="500"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        text
                        v-bind="attrs"
                        v-on="on"
                        class="text-none px-0 mx-0 text-decoration-underline primary--text"
                      >
                        {{ $t("itinerary.Cards.aboutTheEvent") }}
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="headline primary white--text">
                        {{ $t("itinerary.Cards.aboutTheEvent") }}
                      </v-card-title>

                      <v-card-text class="mt-2">
                        {{ event.description }}
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="descriptionDialog = false"
                        >
                          {{ $t("common.close") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div> -->
              </v-col>
              <v-col cols="12" md="6">
                <div v-if="travel.privacy == 1">
                  <v-btn @click="joinTravel" block color="primary" :disabled="!canJoin">
                    {{ canJoin ? $t("itinerary.Cards.join") : $t("itinerary.Cards.joined") }}
                  </v-btn>
                </div>

                <div class="pt-2 text-right" v-show="shouldRequestFeedback">
                  <LeaveFeedBackCard :event="travel" />
                </div>
              </v-col>
            </v-row>

            <!-- <a v-show="false" class="text-decoration-underline primary--text">
              {{ $t("itinerary.Cards.whatYouNeed") }}
            </a> -->
          </div>
        </v-col>
      </v-row>
      <div>
        <div class="example">
          <!-- -->
          <quill-editor
            class="editor"
            ref="myQuillEditor"
            :options="editorOption"
            @ready="editorReady"
            :disabled="true"
          />
        </div>
        <!-- -->
        <!-- <a class="" v-if="canReadMore" @click="readMoreActivated = !readMoreActivated">
          {{ $t(!readMoreActivated ? "buttons.read_more" : "buttons.read_less") }}
        </a> -->
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import firestore from "@/firebase/firestore";
import LeaveFeedBackCard from "@/components/Trip/Itinerary/Cards/LeaveFeedBackCard";
import { quillEditor } from "vue-quill-editor";
import moment from "moment-timezone";
// eslint-disable-next-line import/no-extraneous-dependencies
import "quill/dist/quill.snow.css";

export default {
  props: ["travel"],
  components: {
    LeaveFeedBackCard,
    quillEditor,
  },
  data() {
    return {
      descriptionDialog: false,
      readMoreActivated: false,
      editorOption: {
        modules: {
          toolbar: null,
        },
        placeholder: "",
        readOnly: true,
        theme: "snow",
      },
    };
  },
  computed: {
    timezoneTooltip() {
      const { event } = this.$props;
      if (event.origin && event.origin.time_zone) {
        return `${moment.tz.zone(event.origin.time_zone).name.split("/").pop()}\n time`;
      }
      if (event.remote_tz) {
        return `${moment.tz.zone(moment.tz.guess()).name.split("/").pop()} time`;
      }
      return "";
    },
    timezoneStr() {
      const { event } = this.$props;
      if (event.origin && event.origin.time_zone) {
        const offset = moment.tz
          .zone(event.origin.time_zone)
          .utcOffset(event.origin.date.seconds * 1000);
        return `GMT${offset > 0 ? "-" : "+"}${Math.abs(offset / 60)}`;
        // .abbr(activity.location.date.seconds * 1000);
      }

      if (event.remote_tz) {
        return moment.tz.zone(moment.tz.guess()).abbr(event.origin.date.seconds * 1000);
      }
      return "";
    },
    canReadMore() {
      return this.$props.travel.description.length > 180;
    },
    shouldRequestFeedback() {
      const { travel } = this.$props;
      if (!travel.feedback) return false;
      const meId = this.$store.state.firUser.uid;
      const ratings = this.$props.travel.ratings || {};

      const { date } = travel.destin;
      const feedbackTime = new Date(
        date.seconds * 1000 + +date.toDate().getTimezoneOffset() * 60 * 1000,
      );

      return ratings[meId] == null && feedbackTime.getTime() < new Date().getTime();
    },
    attending() {
      const { travel } = this.$props;
      let users = 0;
      switch (travel.privacy) {
        case 2: // everyone
          users = Object.keys(this.$store.state.trip.tripUsers).length;
          break;
        case 1: // optional
        case 3: // selected
          users = Object.keys(travel.users || {}).length;
          break;
        default:
          break;
      }
      return `${users} ${this.$t("itinerary.Cards.attending")}`;
    },
    canJoin() {
      const { travel } = this.$props;
      const meId = this.$store.state.firUser.uid;
      return travel.privacy === 1 && (travel.users || {})[meId] == null;
    },
    image() {
      const { imageUrls } = this.$store.state;
      const { travel } = this.$props;
      const url = imageUrls[travel.id];
      if (url) {
        return url;
      }
      const { files } = travel;
      if (files && files.header) {
        const cityImages = {};
        Object.values(this.$store.state.trip.cities).forEach((city) => {
          const imageUrl = city.image;
          let imageName;

          if (imageUrl.includes("wikimedia")) {
            imageName = `${city.wiki_code}.${imageUrl.split(/[#?]/)[0].split(".").pop().trim()}`;
          } else {
            imageName = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);
          }
          cityImages[imageName] = imageUrl;
        });
        if (cityImages[files.header] != null) {
          return cityImages[files.header];
        }
      }

      try {
        const fileName = `@/assets/img/${
          files && files.header && !files.header.includes("/")
            ? `${files.header.toLowerCase()}.jpg`
            : "other.jpg"
        }`;
        // eslint-disable-next-line import/no-dynamic-require
        return require(fileName);
      } catch (e) {
        return require("@/assets/img/other.jpg");
      }
    },
  },
  methods: {
    activateReadMore() {
      this.readMoreActivated = true;
    },
    jointravel() {
      if (!this.canJoin) return;
      const tripId = this.$store.getters.currentTripId;
      const meId = this.$store.state.firUser.uid;
      const update = {};
      update[`users.${meId}`] = true;

      firestore.writeTrackedDocument(tripId, "travels", update, this.$props.travel.id);
    },
    editorReady(quill) {
      if (this.$props.travel.quill_description) {
        const quillDescription = JSON.parse(this.$props.travel.quill_description).ops;
        quill.setContents(quillDescription);
      } else {
        quill.setText(this.$props.travel.description);
      }
    },
  },
};
</script>

<style  scoped>
.badge-ribbon {
  /* --ribbon-color: red; */
  position: absolute;
  z-index: 1;
  background: var(--ribbon-color);
  height: 40px;
  width: 40px;
  right: 12px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.badge-ribbon:before,
.badge-ribbon:after {
  content: "";
  position: absolute;
  border-bottom: 40px solid var(--ribbon-color);
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  top: 10px;
  left: 0px;
  transform: rotate(-90deg);
}
.badge-ribbon:after {
  left: auto;
  right: 0px;
  transform: rotate(90deg);
}
</style>
