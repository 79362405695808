import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import login from './firebase/login';
import './filters';
import i18n from './i18n';

Vue.config.productionTip = false;

let app = null;
// wait for firebase auth to init before creating the app
login.waitForLogin((user) => {
  console.log('waitForLogin');
  // init app if not already created
  store.commit('firUserUpdated', user);
  if (user) {
    console.log('waitForLogin->Logged in user', user.uid);
    store.dispatch('userLogedIn', user);

    if (router.currentRoute.meta && router.currentRoute.meta.isForLogin) {
      if (router.startUrl) {
        console.log('pushing to ', router.startUrl);
        router.push({ path: router.startUrl });
      } else {
        router.push({ name: "Trips" });
      }
    }
  }

  if (!app) {
    /* eslint-disable no-new */
    app = new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: (h) => h(App),
    }).$mount('#app');

    const primary = localStorage.getItem("primaryColor");
    if (primary) {
      app.$vuetify.theme.themes.light.primary = primary;
      app.$vuetify.theme.themes.light.secondary = localStorage.getItem("secondaryColor");
    }
  }
});
