<template>
  <v-dialog v-model="dialog" width="100%" transition="dialog-bottom-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-carousel
        v-bind="attrs"
        v-on="on"
        v-model="carousel"
        :cycle="!dialog"
        height="400"
        hide-delimiter-background
        show-arrows-on-hover
        :hide-delimiters="images.length == 1"
        :show-arrows="images.length > 1"
      >
        <v-carousel-item
          v-for="(image, i) in images"
          :key="i"
          :src="image"
          @click="dialog = true"
        ></v-carousel-item>
      </v-carousel>
    </template>
    <v-card>
      <v-row>
        <v-col cols="12" sm="6" md="7">
          <v-carousel
            v-model="carousel"
            height="85vh"
            hide-delimiter-background
            show-arrows-on-hover
            :hide-delimiters="images.length == 1"
            :show-arrows="images.length > 1"
          >
            <v-carousel-item
              v-for="(image, i) in images"
              :key="i"
              style="width: 100%; text-align: center"
            >
              <v-img contain :src="image" style="height: 100%">
                <v-btn
                  class="float-right"
                  icon
                  style="background-color: rgba(100, 100, 100, 0.5)"
                  right
                  x-large
                  @click="dialog = false"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-col>

        <v-col cols="12" sm="6" md="5" style="height: 90vh">
          <div class="pt-3 d-flex flex-column" style="height: 100%">
            <div class="d-flex flex-row">
              <v-avatar x-large class="elevation-8">
                <img alt="UserPic" :src="post.userPic" />
              </v-avatar>
              <div class="ml-3">
                <div class="headline">
                  <b>{{ post.userName }}</b>
                </div>
                <div class="text-caption">
                  {{ post.date.toDate() | toDate("lll") }}
                </div>
              </div>
            </div>

            <div class="d-flex flex-row mt-4 align-center">
              <div class="grey darken-3" style="width: 40px; height: 2px"></div>
              <div class="ml-3">{{ post.text }}</div>
            </div>
            <div
              v-if="canComment"
              class="d-flex flex-row mt-4 text--primary"
              style="font-size: 18px"
            >
              <div md="3">
                <v-icon color="primary">mdi-heart</v-icon>

                <b class="ml-1"
                  >{{
                    $tc("feed.postLikes", likesCount, {
                      likeCounts: likesCount,
                    })
                  }}
                </b>
              </div>
              <div class="ml-8" md="3">
                <v-icon color="primary">mdi-comment</v-icon>
                <b class="ml-1">
                  {{
                    $tc("feed.postComments", commentsCount, {
                      commentsCount: commentsCount,
                    })
                  }}
                </b>
              </div>
            </div>
            <div v-if="canComment">
              <v-divider class="elevation-1 mt-4"></v-divider>
              <v-row class="text-center flex-grow-0">
                <v-col md="6">
                  <v-btn text color="primary" @click="toggleLike">
                    <v-icon left>{{ `mdi-heart${liked ? "" : "-outline"}` }}</v-icon>
                    {{ $t("feed.like") }}
                  </v-btn>
                </v-col>
                <v-col md="6">
                  <v-btn text color="primary" class="ml-12">
                    <v-icon left>mdi-message</v-icon>
                    {{ $t("feed.comment") }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider class="elevation-1"></v-divider>
            </div>
            <CommentsDescription
              v-if="canComment"
              class="flex-grow-1 mt-1 pa-2"
              style="overflow-y: auto; background-color: #f2f2f2"
              :postId="$props.post.id"
              :post="$props.post"
            />
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import firestore from "@/firebase/firestore";
import CommentsDescription from "./CommentsDescription";

export default {
  name: "postDescription",
  props: ["images", "post"],
  components: { CommentsDescription },
  data() {
    return {
      carousel: null,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
    };
  },
  methods: {
    toggleLike() {
      const tripId = this.$store.getters.currentTripId;
      const path = `likes.${this.userId}`;
      const update = {};

      if (this.liked) {
        console.log("set unlikeed");
        update[path] = firestore.fieldDelete();
      } else {
        console.log("set likeed");
        update[path] = true;
      }
      console.log(update);
      firestore.writeTrackedDocument(tripId, "feed", update, this.$props.post.id);
    },
  },
  computed: {
    canComment() {
      if (this.post.allow_comments === undefined) return true;
      return this.post.allow_comments;
    },
    userId() {
      return this.$store.state.firUser.uid;
    },
    liked() {
      const likes = this.post.likes || {};
      return likes[this.userId] != null;
    },
    likesCount() {
      if (!this.post.likes) return 0;
      return Object.keys(this.post.likes).length;
    },
    commentsCount() {
      if (!this.post.comment_ids) return 0;
      return Object.keys(this.post.comment_ids).length;
    },
  },
};
</script>

<style scoped>
</style>
