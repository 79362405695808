/* eslint-disable no-param-reassign */
import Vue from 'vue';
import chat from './chat';
import database from '../firebase/database';
// import storage from '../firebase/storage'
import firestore from '../firebase/firestore';
import notifications from '../plugins/tl-notifications';

const defaultTimeZone = "UTC"; // Intl.DateTimeFormat().resolvedOptions().timeZone;

const tripModule = {

  state: {
    trip: null,
    company: null,
    observingFeed: false,
    tripUsers: {},
    events: {},
    travels: {},
    flights: {},
    cities: {},
    chats: {},
    files: {},
    maps: {},
    feed: {},
    postComments: {},
    subgroups: {},
    users: {},
    contacts: {},
    surveys: {},
    polls: {},
  },
  mutations: {
    updateTripCollectionDocument: (state, payload) => {
      Vue.set(state[payload.collection], payload.document.id, payload.document);
    },
    deleteTripCollectionDocument: (state, payload) => {
      Vue.delete(state[payload.collection], payload.documentId);
    },
    updateDatabaseTripChild: (state, payload) => {
      Vue.set(state[payload.collection], payload.document.id, payload.document.value);
    },
    updateTripDocument: (state, payload) => { state[payload.collection] = payload.document; },
    postCommentAdded: (state, payload) => {
      if (!state.postComments[payload.postId]) {
        Vue.set(state.postComments, payload.postId, [payload.document]);
      } else {
        state.postComments[payload.postId].push(payload.document);
      }
    },
    deleteTrip: (state, currentUserId) => {
      state.events = {};
      state.contacts = {};
      state.travels = {};
      state.cities = {};
      state.maps = {};
      state.flights = {};
      state.feed = {};
      state.chats = {};
      state.files = {};
      state.chat.conversations = {};
      state.surveys = {};
      state.polls = {};
      state.subgroups = {};
      state.postComments = {};
      state.observingFeed = false;
      state.company = null;
      const { trip } = state;

      if (trip) {
        const userIds = Object.keys(trip.user_ids);
        userIds.forEach((userId) => {
          if (currentUserId !== userId) {
            Vue.delete(state.tripUsers, userId);
          }
        });
        state.trip = null;
      }
    },
  },

  getters: {
    currentTripId: (state) => {
      if (state.trip) return state.trip.id;
      return null;
    },

    invitedActivities(state, _, rootState) {
      console.log("filtering activities");
      console.log(state.subgroups);

      return [
        ...Object.values(state.events),
        ...Object.values(state.travels),
      ].filter((activity) => {
        if (activity.privacy < 3) return true;
        // directly invited
        if ((activity.users || {})[rootState.firUser.uid]) return true;
        // check if subgroups
        return Object.keys(activity.subgroups || {})
          .find((subgroupId) => state.subgroups[subgroupId] != null &&
            state.subgroups[subgroupId].user_ids[rootState.firUser.uid]) != null;
      });
    },
    sortedItinerary: (state, getters) => {
      const activities = [...getters.invitedActivities, ...Object.values(state.surveys)];
      return activities.sort((a, b) => (a.sortingDate > b.sortingDate ? 1 : -1));
    },
    sortedPosts: (state) => {
      const posts = Object.values(state.feed).concat(Object.values(state.polls));
      return posts.sort((a, b) => (a.date < b.date ? 1 : -1));
    },
    tripUsers: (state) => {
      const { trip } = state;
      const travelers = [];
      if (!trip) return travelers;
      const allUsers = state.tripUsers;
      const tripUserIds = Object.keys(trip.user_ids);

      return tripUserIds.map((id) => allUsers[id]);
    },
    getTripUserById: (state) => (id) => (state.tripUsers[id] || {}),
    tripUserShortName: (state) => (id) => (((state.tripUsers[id] || {}).name || "").split(" ").shift()),
    conversationWithPartner: (state, _, rootState) => (partner) => Object
      .values(state.chats).find((conversation) => {
        const members = Object.keys(conversation.users);
        const meId = rootState.firUser.uid;
        return !conversation.name &&
          members.length === 2 &&
          members.includes(meId) &&
          members.includes(partner);
      }),

  },
  actions: {
    observeNewTrip: (context, tripId) => {
      console.log('observing trip', tripId);

      if (context.state.trip) {
        notifications.notificationsEnabled(false);
        database.stopTripObservers();
        firestore.unsubscribeTripListeners();
      }

      context.commit('deleteTrip', context.rootState.firUser.uid);
      firestore.observeTrip(context, tripId);
      database.observeTrip(context, tripId);
      setTimeout(() => {
        notifications.checkPermissions();
        notifications.notificationsEnabled(true);
      }, 5000);
    },
    getCompany: (context, companyId) => {
      firestore.getCompany(context, companyId);
    },
    // observeFeed: (context) => {
    //     console.log("observeFeed")
    //     if (!context.state.observingFeed) {
    //         firestore.observeFeed(context, context.getters.currentTripId)
    //     } else {
    //         console.log("joking! already observing")
    //     }
    // },
    observeTripUsers: (context, trip) => {
      console.log('observing users');
      database.observeTripUsers(context, trip.user_ids);
      database.getUsers(context, trip.removed_users || {});
    },
  },
  modules: {
    chat,
  },
};
export default tripModule;
