<template>
  <v-dialog v-model="dialog" width="600">
    <v-card flat v-if="dialog">
      <v-img
        class="white--text align-end"
        height="200px"
        :src="imageSrc || originalImageSrc || require(`@/assets/chat_bck.jpeg`)"
      >
        <div
          class="d-flex flex-column justify-space-between"
          style="height: 200px; background-color: rgba(20, 20, 20, 0.3)"
        >
          <div class="headline d-flex justify-space-between align-center">
            <v-card-title class="primary--text">
              <span>{{ $t("chat.GroupConversation.groupInfo") }}</span>
            </v-card-title>

            <v-btn @click="close" icon color dark class="mr-2">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <input
            type="file"
            style="display: none"
            ref="image"
            accept="image/*"
            @change="onFilePicked"
          />
          <v-card-title>
            <v-text-field
              dark
              v-model="chatName"
              flat
              class="py-0 my-0 mr-4"
              dense
              :label="$t('common.name')"
            ></v-text-field>
            <v-btn outlined dark @click="$refs.image.click()">
              <v-icon>mdi-camera</v-icon>
            </v-btn>
          </v-card-title>
        </div>
      </v-img>

      <v-card-text class="mt-2">
        <v-select
          v-model="selectedUsers"
          :items="travelers"
          :disabled="isGroupChat"
          :menu-props="{ maxHeight: '400', maxWidth: '300' }"
          :label="$t('common.travelers')"
          item-text="name"
          item-value="id"
          multiple
        >
          <template v-slot:selection="{ index }">
            <span v-if="index === 1" class="grey--text"
              >{{ selectedUsers.length }} {{ $t("common.selected") }}</span
            >
          </template>
        </v-select>
        <v-list dense>
          <template v-for="item in chatUsers">
            <v-list-item :key="item">
              <v-avatar color="grey" size="40" class="ma-2">
                <v-img :src="userPic(item)"></v-img>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ getUserName(item) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="`${item}_div`" :inset="item.inset"></v-divider>
          </template>
        </v-list>

        <p v-if="feedback" class="red--text mt-2">{{ feedback }}</p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" v-if="newUsers" :loading="loading" text @click="createChat">
          {{ $t("common.create") }}
        </v-btn>
        <v-btn color="primary" v-if="conversation" text @click="close">
          {{ $t("common.close") }}
        </v-btn>
        <v-btn color="primary" v-if="conversation" :loading="loading" text @click="updateChat">
          {{ $t("common.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import storage from "@/firebase/storage";
import { uuid } from "vue-uuid";

export default {
  props: ["newUsers", "conversation"],

  data: () => ({
    feedback: null,
    selectedUsers: [],
    chatName: "",
    loading: false,
    image: null,
    imageSrc: null,
    originalImageSrc: null,
  }),
  computed: {
    dialog() {
      return this.$props.newUsers != null || this.$props.conversation != null;
    },
    isGroupChat() {
      return this.$props.conversation === this.$store.getters.currentTripId;
    },
    travelers() {
      const meId = this.$store.state.firUser.uid;
      return this.$store.getters.tripUsers.filter((u) => u.id !== meId);
    },
    chatUsers() {
      const meId = this.$store.state.firUser.uid;
      return [...this.selectedUsers, meId];
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },

    validate() {
      if (this.chatName === "") {
        this.feedback = this.$t("chat.GroupConversation.feedbackName");
        return false;
      }
      if (this.selectedUsers.length === 0) {
        this.feedback = this.$t("chat.GroupConversation.feedbackPartner");
        return false;
      }
      this.feedback = null;
      return true;
    },
    async updateChat() {
      if (!this.validate()) return;
      this.loading = true;

      const data = {
        name: this.chatName,
        users: this.chatUsers,
      };
      const imagePath = await this.uploadFileIfExists();
      if (imagePath) {
        data.image_path = imagePath;
      }

      this.$emit("update", data);
    },
    async createChat() {
      if (!this.validate()) return;
      this.loading = true;

      const data = {
        name: this.chatName,
        users: this.chatUsers,
      };
      const imagePath = await this.uploadFileIfExists();
      if (imagePath) {
        data.image_path = imagePath;
      }
      this.$emit("create", data);
    },
    getUserName(userId) {
      return this.$store.getters.getTripUserById(userId).name;
    },
    async uploadFileIfExists() {
      let imagePath;
      if (this.image) {
        const imageName = `${uuid.v4()}.${this.image.name.split(".").pop().toLowerCase()}`;
        const tripId = this.$store.getters.currentTripId;
        imagePath = `groups/${tripId}/chats/groupImages/${imageName}`;
        await storage.uploadFile(this.image, imagePath);
      }
      return imagePath;
    },
    onFilePicked(e) {
      const { files } = e.target;
      if (files[0] !== undefined) {
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          [this.image] = files;
          this.imageSrc = fr.result;
        });
      }
    },
    userPic(userId) {
      return (
        this.$store.getters.getImageUrlById(userId) || require("@/assets/profile_pic_empty.png")
      );
    },
    reset() {
      this.loading = false;
      this.image = null;
      this.imageSrc = null;
      this.originalImageSrc = null;
      this.selectedUsers = [];
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    "$props.newUsers": function (users) {
      this.selectedUsers = users;
      if (!users) {
        this.reset();
      }
    },
    conversation(newConver) {
      if (newConver) {
        this.originalImageSrc = this.$store.getters.getImageUrlById(newConver);
        const chat = this.$store.state.trip.chats[newConver];
        console.log(chat);
        this.chatName = chat.name;
        const meId = this.$store.state.firUser.uid;
        this.selectedUsers = Object.keys(chat.users).filter((u) => u !== meId);
      } else {
        this.reset();
      }
    },
  },
};
</script>
