  <template>
  <v-list dense rounded nav>
    <v-list-item class="my-4" v-for="item in items" :key="item.title" link :to="item.route">
      <v-list-item-icon>
        <v-badge
          v-if="item.icon == 'mdi-chat' && newMessages > 0"
          color="red"
          :content="newMessages"
        >
          <v-icon>{{ item.icon }}</v-icon>
        </v-badge>
        <v-icon v-else>{{ item.icon }}</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: ["trip"],
  data() {
    return {
      drawer: null,
      items: [
        {
          title: "navbar.menu.itinerary",
          icon: "mdi-calendar",
          route: "/trip/itinerary",
        },
        {
          title: "navbar.menu.groupFeed",
          icon: "mdi-rss",
          route: "/trip/feed",
        },
        {
          title: "navbar.menu.chat",
          icon: "mdi-chat",
          route: "/trip/chat",
        },
        {
          title: "navbar.menu.allTrips",
          icon: "mdi-exit-to-app",
          route: "/",
        },
      ],
    };
  },
  computed: {
    user() {
      const user = this.$store.state.currentUser;
      console.log(user);
      if (!user) return {};
      return {
        name: user.name,
        profilePic: this.$store.getters.getImageUrlById(user.id),
      };
    },
    tripName() {
      return this.$store.state.trip.trip.name;
    },
    newMessages() {
      return Object.values(this.$store.state.trip.chat.conversations).reduce(
        (t, c) => t + c.newMessages,
        0,
      );
    },
    companyImage() {
      const { company } = this.$store.state.trip;

      if (company) {
        return this.$store.getters.getImageUrlById(company.id);
      }
      return null;
    },
  },
  methods: {
    setTitle(tripName, messages) {
      const title = tripName || "Trip Loop";
      if (messages > 0) {
        document.title = `${title} (${messages})`;
      } else {
        document.title = title;
      }
    },
  },
  created() {
    this.setTitle(this.tripName, this.newMessages);
  },
  watch: {
    tripName(newVal) {
      this.setTitle(newVal, this.newMessages);
    },
    newMessages(newVal) {
      this.setTitle(this.tripName, newVal);
    },
  },
};
</script>
