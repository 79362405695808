<template>
  <div>
    <v-img
      :src="require('@/assets/dotted_bck.png')"
      style="position: absolute; max-width: 50vw"
    ></v-img>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "CurrentTrip",
  props: ["tripId"],

  created() {
    console.log("home created, current user is ", this.$store.state.firUser.uid);
    const { tripId } = this.$props;
    console.log("current trip created with tripId:", tripId);

    if (tripId) {
      const currentTrip = this.$store.state.trip;
      if (!currentTrip || currentTrip.id !== tripId) {
        this.$store.dispatch("observeNewTrip", tripId);
        localStorage.setItem("currentTrip", tripId);
      } else {
        console.log("reusing trip");
      }
    }
  },
  computed: {
    company() {
      return this.$store.state.trip.company;
    },
  },
  watch: {
    company(company) {
      if (company && company.primary_color) {
        this.$vuetify.theme.themes.light.primary = company.primary_color;
        this.$vuetify.theme.themes.light.secondary = company.secondary_color;
        localStorage.setItem("primaryColor", company.primary_color);
        localStorage.setItem("secondaryColor", company.secondary_color);
      } else {
        this.$vuetify.theme.themes.light.primary = "#5D74E2";
      }
    },
  },
};
</script>
