<template>
  <v-card>
    <v-card-title class="d-flex flex-row justify-space-between headline primary white--text">
      <span>{{ $t(`surveys.${type}`) }}</span>
      <v-btn icon color="white" v-if="type == 'survey'" @click="closeAndReset()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="pt-3">
      <v-card-subtitle
        class="pb-1"
        v-html="$t('surveys.surveys.questionStep', { current: index + 1, total })"
      ></v-card-subtitle>
      <v-progress-linear
        class="mx-2"
        :value="((index + 1) / (total + 1)) * 100"
      ></v-progress-linear>
    </v-card-text>
    <v-card-title v-html="question.question"></v-card-title>

    <v-card-text>
      <div class="vue-survey">
        <div class="ans-cnt">
          <div v-for="(option, index) in question.options" :key="index" class="ans">
            <div
              :class="{
                'ans-no-vote noselect': true,
                active: selected == index,
              }"
              @click.prevent="selectedAnswer(index)"
            >
              <span class="txt" v-html="option"></span>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn v-if="canBack" text color="primary" @click="goBack">{{ $t("common.back") }}</v-btn>
      <v-btn v-if="selected != null" class="primary" @click="nextQuestion">{{
        canNext ? $t("common.next") : $t("surveys.surveys.finish")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["question", "canBack", "canNext", "selected", "index", "total", "type"],

  methods: {
    selectedAnswer(index) {
      this.$emit("selectedAnswer", index);
    },
    closeAndReset() {
      this.$emit("cancel");
    },
    nextQuestion() {
      this.$emit("nextQuestion");
    },
    goBack() {
      console.log("go back");
      this.$emit("prevQuestion");
    },
  },
};
</script>

<style scoped>
.vue-survey {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.vue-survey .noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.vue-survey .ans-cnt .ans {
  position: relative;
  margin-top: 10px;
}
.vue-survey .ans-cnt .ans:first-child {
  margin-top: 0;
}

.vue-survey .ans-cnt .ans-no-vote {
  text-align: center;
  border: 2px solid var(--v-primary-base);
  box-sizing: border-box;
  border-radius: 100px;
  cursor: pointer;
  padding: 5px 0;
  transition: background 0.2s ease-in-out;
  -webkit-transition: background 0.2s ease-in-out;
  -moz-transition: background 0.2s ease-in-out;
}

.vue-survey .ans-cnt .ans-no-vote .txt {
  color: var(--v-primary-base);
  transition: color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out;
  -moz-transition: color 0.2s ease-in-out;
}

.vue-survey .ans-cnt .ans-no-vote.active {
  background: var(--v-primary-base);
}

.vue-survey .ans-cnt .ans-no-vote.active .txt {
  color: #fff;
}
</style>
