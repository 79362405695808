<template>
  <v-app-bar dense clipped-left app v-if="shouldShow" elevation="0">
    <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
    <slot></slot>

    <div class="pt-2" style="text-align: center; max-width: 160px">
      <v-img
        alt="Company Logo"
        class="shrink"
        contain
        :src="companyImage || require('@/assets/logo.png')"
        transition="scale-transition"
        height="38"
      />
      <!-- <div class="text-caption grey--text">powered by Trip Loop</div> -->
    </div>

    <v-spacer></v-spacer>
    <template v-if="user">
      <NavbarProfile :user="user" />
    </template>
    <template v-else>
      <v-btn text v-if="currentRoute == 'SignUp'" :to="{ name: 'Login' }">{{
        $t("navbar.login")
      }}</v-btn>
      <v-btn text v-if="currentRoute == 'Login'" :to="{ name: 'SignUp' }">{{
        $t("navbar.logout")
      }}</v-btn>
    </template>
  </v-app-bar>
</template>

<script>
import NavbarProfile from "./NavBarProfile";

export default {
  name: "Navbar",
  components: { NavbarProfile },

  data() {
    return {
      tab: null,
    };
  },
  computed: {
    user() {
      return this.$store.state.currentUser;
    },
    currentRoute() {
      return this.$route.name;
    },
    companyImage() {
      const { company } = this.$store.state.trip;

      if (company) {
        return this.$store.getters.getImageUrlById(company.id);
      }
      return null;
    },

    shouldShow() {
      return !(this.$route.meta && (this.$route.meta.isAnonymus || this.$route.meta.isForLogin));
    },
  },
};
</script>

<style >
.v-toolbar__content {
  padding-right: 0px;
}
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>
