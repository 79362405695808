<template>
  <v-container>
    <div v-for="(comment, index) in previewComments" :key="index">
      <div class="my-1 d-flex flex-row">
        <b>{{ comment.senderName }}</b>
        <span class="ml-3">{{ comment.text }}</span>
      </div>
    </div>
    <v-btn
      text
      small
      class="my-1"
      rounded
      color="primary"
      v-show="commentsCount > 2"
      @click="toggleComments"
      >{{
        allComments ? $t("feed.hideComments") : $t("feed.allComments", { arg: commentsCount })
      }}</v-btn
    >
    <v-text-field
      v-model="newCommentText"
      :placeholder="$t('feed.comment')"
      filled
      rounded
      dense
      hide-details
      v-on:keyup.enter="postComment"
    ></v-text-field>
  </v-container>
</template>

<script>
import firestore from "@/firebase/firestore";

export default {
  props: ["postId"],
  data: () => ({
    allComments: false,
    newCommentText: "",
  }),
  computed: {
    commentsCount() {
      const comments = this.$store.state.trip.postComments[this.$props.postId];
      if (!comments) return 0;
      return comments.length;
    },
    previewComments() {
      const comments = this.$store.state.trip.postComments[this.$props.postId];
      if (!comments) return [];
      const allComments = comments.map((c) => ({
        text: c.comment,
        senderName: (this.$store.getters.getTripUserById(c.sender_id).name || "")
          .split(" ")
          .shift(),
        date: c.date,
      }));
      return this.allComments ? allComments : allComments.slice(0, Math.min(2, allComments.length));
    },
  },
  methods: {
    toggleComments() {
      this.allComments = !this.allComments;
    },
    postComment() {
      if (this.newCommentText !== "") {
        const comment = {
          comment: this.newCommentText,
          date: new Date(),
          sender_id: this.$store.state.firUser.uid,
        };

        const tripId = this.$store.getters.currentTripId;

        firestore.writePostComment(tripId, this.$props.postId, comment);
        this.newCommentText = "";
      }
    },
  },
};
</script>
