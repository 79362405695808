<template>
  <v-card v-if="!started">
    <v-card-title class="d-flex flex-row justify-space-between headline primary white--text">
      <span>{{ $t(`surveys.${survey.type}`) }}</span>
      <v-btn icon color="white" v-if="survey.type == 'survey'" @click="closeAndReset">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-title>{{ survey.name }}</v-card-title>
    <v-card-text class="mt-0 pt-0">
      <span class="text-caption">
        {{
          $tc("surveys.surveys.noQuestions", survey.questions.length, {
            noQuestions: survey.questions.length,
          })
        }}
      </span>

      <p class="mt-2">
        {{ survey.description }}
      </p>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="exitSurvey">{{ $t("common.notYet") }}</v-btn>
      <v-btn class="primary" @click="started = true">{{ $t(`surveys.take_${survey.type}`) }}</v-btn>
    </v-card-actions>
  </v-card>
  <v-card v-else-if="finished">
    <v-card-title header class="primary white--text">{{ $t("common.finished") }}</v-card-title>
    <v-card-text class="mt-2" v-if="survey.type == 'quiz'">
      <div class="text-center">
        <p class="text-h6">
          {{
            $tc("surveys.surveys.score", score.total, {
              noQuestion: score.total,
              correct: score.correct,
            })
          }}
        </p>
        <v-icon size="70" color="primary">mdi-check-circle</v-icon>
        <p class="text-h5 mt-2">{{ score.grade }}%</p>
      </div>
    </v-card-text>
    <v-card-text v-else class="mt-2">
      <div class="text-center">
        <p class="text-h6">{{ $t("surveys.surveys.thanks") }}</p>
        <v-icon size="70" color="primary">mdi-check-circle</v-icon>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="primary" @click="exitSurvey">{{ $t("common.exit") }}</v-btn>
    </v-card-actions>
  </v-card>
  <SurveyQuestion
    v-else
    :key="questionIndex"
    :index="questionIndex"
    :type="survey.type"
    :total="survey.questions.length"
    :question="question"
    :selected="answers.length > questionIndex ? answers[questionIndex] : null"
    @cancel="closeAndReset"
    @selectedAnswer="selectedAnswer"
    @nextQuestion="nextQuestion"
    @prevQuestion="--questionIndex"
    :canBack="questionIndex > 0"
    :canNext="questionIndex < survey.questions.length - 1"
  />
</template>

<script>
import firestore from "@/firebase/firestore";
import SurveyQuestion from "./SurveyQuestion";

export default {
  props: ["survey"],
  components: { SurveyQuestion },
  data: () => ({
    started: false,
    finished: false,
    questionIndex: 0,
    answers: [],
  }),
  computed: {
    question() {
      return this.$props.survey.questions[this.questionIndex];
    },
    score() {
      let score = 0;
      const { questions } = this.$props.survey;
      questions.forEach((question, i) => {
        if (question.answer === this.answers[i]) {
          score += 1;
        }
      });
      console.log("person scored", score, "out of ", questions.length);
      return {
        correct: score,
        grade: Math.round((score / questions.length) * 100),
        total: questions.length,
      };
    },
  },
  methods: {
    closeAndReset() {
      this.answers = [];
      this.started = false;
      this.finished = false;
      this.questionIndex = 0;
      this.exitSurvey();
    },
    selectedAnswer(answer) {
      if (this.answers.length < this.questionIndex + 1) {
        console.log("pushing answer ", answer, " question", this.questionIndex);
        this.answers.push(answer);
      } else {
        this.$set(this.answers, this.questionIndex, answer);
        console.log("reseting answer ", answer, "question", this.questionIndex);
      }
    },
    nextQuestion() {
      if (this.questionIndex < this.$props.survey.questions.length - 1) {
        this.questionIndex += 1;
      } else {
        console.log("finished!");
        this.saveSurvey();
        this.finished = true;
      }
    },
    saveSurvey() {
      const update = {};
      update[`responses.${this.$store.state.firUser.uid}`] = this.answers;

      firestore.writeTrackedDocument(
        this.$store.getters.currentTripId,
        "surveys",
        update,
        this.$props.survey.id,
      );
    },
    exitSurvey() {
      this.$emit("exit");
    },
  },
};
</script>
