<template>
  <v-container>
    <a
      href="https://www.iubenda.com/terms-and-conditions/79904406"
      class="iubenda-white no-brand iubenda-embed iub-body-embed"
      title="Terms and Conditions"
    >
      Terms and Conditions
    </a>
  </v-container>
</template>

<script>
export default {
  name: "TermsAndConditions",
  data() {
    return {};
  },
  mounted() {
    function loader(w, d) {
      const s = d.createElement("script");
      const tag = d.getElementsByTagName("script")[0];
      s.src = "https://cdn.iubenda.com/iubenda.js";
      tag.parentNode.insertBefore(s, tag);
    }
    loader(window, document);
  },
};
</script>
