<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="400">
      <v-card v-if="error">
        <v-card-title class="primary white--text" primary-title>{{ error.title }}</v-card-title>
        <v-card-text class="mt-2">{{ error.message }}</v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="redirectHome">{{ $t("common.close") }}</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else-if="!group">
        <v-card-title class="primary white--text" primary-title>{{
          $t("joinTrip.loadingJoin")
        }}</v-card-title>
        <v-card-text class="mt-2">{{ $t("common.wait") }}</v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-title class="primary white--text" primary-title>{{
          $t("joinTrip.loadingJoin")
        }}</v-card-title>
        <v-card-text class="mt-2">
          <h3>{{ $t("joinTrip.messageSuccess", { tripName: group.name }) }}.</h3>
          <br />
          <p>{{ $t("joinTrip.message") }}</p>
          <v-row dense v-if="!group.isAdmin">
            <v-col cols="6">
              <a href="https://apps.apple.com/app/id1219816175" target="_blank">
                <v-img width="160" :src="require('@/assets/ios.png')" />
              </a>
            </v-col>
            <v-col cols="6">
              <a
                href="https://play.google.com/store/apps/details?id=com.triploop.triploop"
                target="_blank"
              >
                <v-img width="160" :src="require('@/assets/android.png')" />
              </a>
            </v-col>
          </v-row>
          <br />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="exitSuccess">{{ $t("joinTrip.continue") }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/functions";

export default {
  name: "JoinTrip",
  data() {
    return {
      dialog: true,
      group: null,
      error: null,
    };
  },
  methods: {
    redirectHome() {
      this.$router.push({
        name: "Home",
      });
    },
    exitSuccess() {
      this.dialog = false;
      if (this.group) {
        // this.$router.push({ name: "Trips" });
        this.$router.push({
          name: "Itinerary",
          params: { tripId: this.group.id },
        });
      } else {
        this.redirectHome();
      }
    },
  },
  created() {
    const { tripId, group: subgroup } = this.$route.query;
    if (tripId) {
      console.log(this.$route.query);
      const requestJoinGroup = firebase.functions().httpsCallable("trip-requestJoinGroup");
      console.log("joining with data");
      console.log(tripId);
      requestJoinGroup({ tripId, subgroupId: subgroup })
        .then((result) => {
          console.log(result);
          this.group = result.data;
        })
        .catch((error) => {
          //   var message = error.message;
          //   console.log(message);
          const { code, message, details } = error;
          console.log(details);
          console.log(message);
          console.log(code);
          this.error = {
            title: this.$i18n.t(`errors.${details.localized_title}`, {
              arg: details.title_arg || "",
            }),
            message: this.$i18n.t(`errors.${details.localized_message}`, {
              arg: details.message_arg || "",
            }),
          };
          //   this.error = message + this.$t("joinTrip.errorMessage");
        });
    }
  },
};
</script>
