<template>
  <v-dialog v-if="canCreateChat" v-model="dialog" width="600">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" text color="primary">
        <v-icon left>mdi-comment-edit-outline</v-icon>
        <!-- {{ $t("chat.new") }} -->
        {{ $t("chat.NewConversationUsers.newChat") }}
      </v-btn>
    </template>

    <v-card>
      <div class="headline primary d-flex justify-space-between align-center">
        <v-card-title class="white--text">
          <span>{{ $t("chat.NewConversationUsers.newChat") }}</span>
        </v-card-title>
        <v-btn icon color="white" class="mr-2" @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="travelers"
          hide-default-footer
          show-select
          item-key="id"
          selectable-key="id"
          v-model="selected"
          :items-per-page="100"
          class="elevation-1"
        >
          <template v-slot:[`item.profile_pic`]="{ item }">
            <v-avatar color="grey" size="40" class="my-2">
              <v-img :src="userPic(item.id)"></v-img>
            </v-avatar>
          </template>
        </v-data-table>
        <p v-if="feedback" class="red--text mt-2">{{ feedback }}</p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="createChat">{{ $t("common.create") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <div v-else></div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      feedback: null,
      selected: [],
      ChatSettings: {
        allUsers: 0,
        admins: 1,
        withAdmins: 2,
      },
    };
  },
  computed: {
    headers() {
      return [
        {
          value: "profile_pic",
          width: 30,
        },
        {
          text: this.$i18n.t("common.name"),
          value: "name",
        },
      ];
    },
    travelers() {
      return this.$store.getters.tripUsers.filter((u) => u.id !== this.userId);
    },
    selectedIds() {
      return this.selected.map((a) => a.id);
    },
    userId() {
      return this.$store.state.firUser.uid;
    },
    imAdmin() {
      return this.isUserAdmin(this.userId);
    },
    chatSettings() {
      return this.$store.state.trip.trip.chat_settings;
    },
    canCreateChat() {
      if (this.chatSettings == null) return true;
      return (
        this.chatSettings === this.ChatSettings.allUsers ||
        this.chatSettings === this.ChatSettings.withAdmins ||
        (this.chatSettings === this.ChatSettings.admins && this.imAdmin)
      );
    },
  },
  methods: {
    createChat() {
      if (this.selectedIds.length === 0) {
        this.feedback = this.$t("chat.NewConversationUsers.feedback");
        return;
      }

      if (
        this.chatSettings === this.ChatSettings.withAdmins &&
        !(this.imAdmin || this.selectedIds.some((u) => this.isUserAdmin(u)))
      ) {
        this.feedback = this.$t("chat.NewConversationUsers.feedback2");
        return;
      }

      this.feedback = null;
      this.$emit("users", this.selectedIds);
      this.closeDialog();
    },
    isUserAdmin(user) {
      return this.$store.state.trip.trip.user_ids[user];
    },
    userPic(userId) {
      return (
        this.$store.getters.getImageUrlById(userId) || require("@/assets/profile_pic_empty.png")
      );
    },
    closeDialog() {
      this.selected = [];
      this.dialog = false;
    },
  },
};
</script>
