/* eslint-disable no-param-reassign */
import firebase from 'firebase/app';
import 'firebase/firestore';
import notifications from '../plugins/tl-notifications';

const firestore = firebase.firestore();

let listeners = [];

const tripRef = (tripId) => firestore.collection('groups').doc(tripId);

const getHeaderImage = (context, headerCapable) => {
  if (!headerCapable.files || !headerCapable.files.header) return;
  // console.log('getting header of', headerCapable.name)
  context.dispatch('getImageUrl', { path: headerCapable.files.header, id: headerCapable.id });
};

const addListener = (context, ref, collection, modifiedCallback) => {
  const listener = ref.onSnapshot((snapshot) => {
    snapshot.docChanges().forEach((change) => {
      const document = { ...change.doc.data(), id: change.doc.id, collection };

      if (change.type === "removed") {
        console.log('removed document');
        // context.commit(remove, change.doc.id)
        context.commit('deleteTripCollectionDocument', { collection, documentId: change.doc.id });
      } else {
        if (modifiedCallback) {
          modifiedCallback(document, change.type);
        }
        context.commit('updateTripCollectionDocument', { collection, document });
      }
    });
  }, (error) => {
    console.log(error);
  });
  listeners.push(listener);
};

const addSubcollectionListener = (context, tripId, collection, trackDelete, modifiedCallback) => {
  let ref = tripRef(tripId).collection(collection);
  if (trackDelete || false) {
    ref = ref.where('deleted', '==', false);
  }

  addListener(context, ref, collection, modifiedCallback);
};

const deleteDocument = (tripId, collection, documentId) => {
  tripRef(tripId).collection(collection).doc(documentId).delete();
};

const observeEvents = (context, tripId) => {
  const collection = "events";
  const modifiedCallback = (event) => {
    getHeaderImage(context, event);
    event.collection = collection;
    event.sortingDate = event.location.date.toDate();
    event.display_timezone = event.location && event.location.time_zone ?
      event.location.time_zone : event.time_zone;
    if (event.time_zone === 'US/Pacific') {
      event.time_zone = 'America/Los_Angeles';
    }
  };

  const ref = tripRef(tripId).collection(collection).where('deleted', '==', false);

  addListener(context, ref.where("privacy", "<=", 2), collection, modifiedCallback);
  addListener(context, ref.where("privacy", "==", 3), collection, modifiedCallback);
};

const observeTravels = (context, tripId) => {
  const collection = "travels";
  const modifiedCallback = (travel) => {
    getHeaderImage(context, travel);
    travel.collection = collection;
    travel.sortingDate = travel.origin.date.toDate();
    travel.display_timezone = travel.origin && travel.origin.time_zone ?
      travel.origin.time_zone : travel.time_zone;
    if (travel.time_zone === 'US/Pacific') {
      travel.time_zone = 'America/Los_Angeles';
    }
  };
  const ref = tripRef(tripId).collection(collection).where('deleted', '==', false);

  addListener(context, ref.where("privacy", "<=", 2), collection, modifiedCallback);
  addListener(context, ref.where("privacy", "==", 3), collection, modifiedCallback);
  // where(`users.${context.rootState.firUser.uid}`, "==", true)
};

const observeSubgroups = (context, tripId) => addSubcollectionListener(context, tripId, 'subgroups', false);

const observeFlights = (context, tripId) => addSubcollectionListener(context, tripId, 'flights', true, (flight) => {
  getHeaderImage(context, flight);
});

const observeCities = (context, tripId) => addSubcollectionListener(context, tripId, 'cities', false);
const observeMaps = (context, tripId) => addSubcollectionListener(context, tripId, 'maps', true);

const observePostComments = (context, tripId, postId) => {
  const ref = tripRef(tripId).collection('feed').doc(postId).collection("comments")
    .orderBy("date", "asc");

  const listener = ref.onSnapshot((snapshot) => {
    snapshot.docChanges().forEach((change) => {
      const document = { ...change.doc.data(), id: change.doc.id };
      if (change.type === "added") {
        context.commit('postCommentAdded', { postId, document });
      }
    });
  });
  listeners.push(listener);
};
const observePosts = (context, tripId) => {
  addSubcollectionListener(context, tripId, 'feed', true, (post, update) => {
    if (update === "added") {
      observePostComments(context, tripId, post.id);
      notifications.newPostNotification(post);
    }
  });
};

const observePolls = (context, tripId) => {
  addSubcollectionListener(context, tripId, 'polls', true);
};
const observeSurveys = (context, tripId) => {
  addSubcollectionListener(context, tripId, 'surveys', true, (survey) => {
    survey.collection = 'surveys';
    survey.time_zone = "UTC";
    survey.sortingDate = survey.date.toDate();
  });
};

const observeChats = (context, tripId) => {
  const meId = context.rootState.firUser.uid;
  const ref = tripRef(tripId).collection('chats').where(`users.${meId}`, '==', true);

  addListener(context, ref, 'chats', (conversation, update) => {
    if (update === "added") {
      context.dispatch('observeMessages', conversation.id);
    }
    if (conversation.image_path) {
      context.dispatch('getImageUrl', { path: conversation.image_path, id: conversation.id });
    }
  });
};
const observeFeed = (context, tripId) => {
  context.commit("updateTripDocument", { collection: "observingFeed", document: true });
  observePosts(context, tripId);
  observePolls(context, tripId);
};

const getCompany = (context, companyId) => {
  console.log(`fetching companies for ${companyId}`);
  firestore.collection('companies').doc(companyId).get().then((snapshot) => {
    const company = { ...snapshot.data(), id: companyId };
    context.commit('updateTripDocument', { collection: 'company', document: company });
    if (company.logo) {
      context.dispatch('getImageUrl', { path: company.logo, id: company.id });
    }
  });
};

const observeTrip = (context, tripId) => {
  const listener = firestore.collection('groups').doc(tripId).onSnapshot((doc) => {
    const trip = { ...doc.data(), id: tripId };
    context.commit('updateTripDocument', { collection: 'trip', document: trip });
    context.dispatch('observeTripUsers', trip);
    console.log('observetrip');
    getHeaderImage(context, trip);
    if (trip.company && (!context.state.company || context.state.company.id !== trip.company)) {
      getCompany(context, trip.company);
    }
  });
  listeners.push(listener);

  observeEvents(context, tripId);
  observeSubgroups(context, tripId);
  observeCities(context, tripId);
  // observeMaps(context, tripId);
  observeTravels(context, tripId);
  // observeFlights(context, tripId)
  observeChats(context, tripId);
  observeSurveys(context, tripId);
  observeFeed(context, tripId);
};

const writeDocument = (tripId, collection, data, documentId) => {
  if (documentId) {
    return tripRef(tripId).collection(collection).doc(documentId).update(data);
  }
  return tripRef(tripId).collection(collection).add(data);
};

const writeTrackedDocument = (tripId, collection, data, documentId, deleted) => {
  console.log("firestore->writeTrackedDocument");
  console.log(
    {
      tripId, collection, data, documentId, deleted,
    },
  );
  const event = {
    ...data,
    modified: new Date(),
    server_modified: firebase.firestore.FieldValue.serverTimestamp(),
    deleted: deleted || false,
  };

  return writeDocument(tripId, collection, event, documentId);
};

const writePostComment = async (tripId, postId, data) => {
  console.log("write post comment");
  const ref = await tripRef(tripId).collection("feed").doc(postId).collection("comments")
    .add({ ...data, server_modified: firebase.firestore.FieldValue.serverTimestamp() });

  writeTrackedDocument(tripId, "feed", {
    [`comment_ids.${ref.id}`]: true,
  }, postId);
};

const getGeo = (coordinates) => (coordinates ?
  new firebase.firestore.GeoPoint(coordinates.lat, coordinates.long) : null);

const writeCity = (tripId, collection, cityName, data) => {
  const city = { ...data };
  city.north_east = getGeo(data.north_east);
  city.south_west = getGeo(data.south_west);
  city.center = getGeo(data.center);
  return tripRef(tripId).collection(collection).doc(cityName).set(city);
};

const createTrip = ((tripId, data) => tripRef(tripId).set(data));

const updateTrip = (tripId, data) => tripRef(tripId).update(data);

const getTrips = (context, userId) => {
  console.log('requesting trips with id: ', userId);
  firestore.collection('groups').where(`user_ids.${userId}`, '<=', true).get().then((snapshot) => {
    // firestore.collection('groups').doc('clibR9LewVSPQbYANpqu4tr3hVH3_0').get().then(snapshot => {
    const trips = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    trips.forEach((trip) => {
      getHeaderImage(context, trip);
      if (!trip.start_date) {
        trip.start_date = new firebase.firestore.Timestamp(0, 0);
      }
      if (!trip.end_date) {
        trip.end_date = new firebase.firestore.Timestamp(0, 0);
      }
    });
    trips.sort((a, b) => b.end_date.seconds - a.end_date.seconds);
    context.commit('tripsUpdated', trips);
  })
    .catch((error) => {
      console.log(error.message);
    });
};

const unsubscribeTripListeners = () => {
  listeners.forEach((listener) => { listener(); });
  listeners = [];
};

const fieldDelete = firebase.firestore.FieldValue.delete;
const generateId = () => firestore.collection("any").doc().id;

export default {
  observeTrip,
  // observeFeed,
  getTrips,
  unsubscribeTripListeners,
  deleteDocument,
  updateTrip,
  createTrip,
  writeTrackedDocument,
  getGeo,
  writeDocument,
  writeCity,
  getCompany,
  fieldDelete,
  generateId,
  writePostComment,
};
