<template>
  <v-container fill-height fluid style="width: 50vw">
    <v-row align="center" justify="center">
      <v-col>
        <v-card>
          <v-card-title>
            Are you sure you want to delete your account? This action cannot be undone.
          </v-card-title>
          <v-card-text v-show="errorMessage">
            <p class="red--text">{{ errorMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="$router.push('/')">Go Back</v-btn>
            <v-btn color="error" @click="confirmDelete">Confirm Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import login from "@/firebase/login";

export default {
  data() {
    return {
      errorMessage: null,
    };
  },
  methods: {
    async confirmDelete() {
      try {
        await login.deleteUser();
        await new Promise((resolve) => setTimeout(resolve, 500));
        this.$router.push("/");
      } catch (error) {
        console.log(error);
        this.errorMessage = "Credentials expired, please sign out and sign in again and try again";
      }
    },
  },
};
</script>
