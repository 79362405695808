<template>
  <div class="primary" style="height: 100%">
    <div class="d-none d-sm-flex">
      <v-img
        :src="require('@/assets/dotted_bck.png')"
        style="position: absolute; max-width: 80vw"
      ></v-img>
      <v-img
        :src="require('@/assets/shapes/01.png')"
        style="position: absolute; max-width: 30vw; right: 0; bottom: 0"
      ></v-img>
      <v-img
        :src="require('@/assets/shapes/02.png')"
        style="position: absolute; max-width: 10vw; right: 20%; bottom: 15%"
      ></v-img>
      <v-img
        :src="require('@/assets/shapes/03.png')"
        style="position: absolute; max-width: 30vw; top: 40%; left: 0"
      ></v-img>
      <v-img
        :src="require('@/assets/shapes/06.png')"
        style="position: absolute; max-width: 70vw; bottom: 0; left: 0"
      ></v-img>
      <p class="white--text" style="position: absolute; bottom: 20px; left: 20px">
        Powered by Trip Loop
      </p>
    </div>
    <div>
      <div
        style="
          margin-top: calc(20vh - 80px);
          margin-left: calc(50% - 372px);
          position: absolute;
          z-index: 1;
          width: 744px;
        "
      >
        <div class="circle d-flex justify-center">
          <img
            :src="companyImage || require('@/assets/icon.png')"
            style="margin-top: 40px; max-width: 120px; max-height: 120px; height: auto; width: auto"
          />
        </div>
      </div>
    </div>
    <div style="padding-top: 20vh">
      <v-card max-width="544" class="mx-auto px-4" style="margin-top: 40px; padding-top: 80px">
        <v-card-title class="text-h3 font-weight-black">{{ $t("login.login") }}</v-card-title>
        <v-card-text>
          <v-form lazy-validation ref="form">
            <v-text-field
              :label="$t('common.email')"
              v-model="email"
              append-icon="mdi-email"
              :rules="[rules.required, rules.email]"
            />
            <v-text-field
              :label="$t('login.password')"
              v-model="password"
              :rules="[rules.required, rules.min]"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="input-10-1"
              hint="At least 6 characters"
              counter
              @click:append="show = !show"
            />
            <p class="red--text" v-if="feedback">{{ feedback }}</p>
            <v-spacer></v-spacer>
            <div class="text-right">
              <ForgotPassword :email="email" ref="forgot_dialog" @send-email="forgotPassword" />
            </div>
          </v-form>
        </v-card-text>
        <span class="ml-2 text-caption grey--text text--darken-2">
          {{ $t("login.no_account") }}
        </span>

        <v-card-actions>
          <v-btn
            :to="{ name: 'SignUp', params: $route.params }"
            class="px-9"
            text
            outlined
            style="border-width: 2px"
          >
            {{ $t("login.signUp") }}
          </v-btn>
          <v-btn
            color="primary"
            dark
            class="flex-grow-1"
            @click="emailSignIn()"
            :loading="loading"
            >{{ $t("login.login") }}</v-btn
          >
        </v-card-actions>
        <div class="text-caption pt-1 pb-2">
          <span v-html="$t('login.privacyText')"> </span>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import login from "@/firebase/login";
import ForgotPassword from "./ForgotPassword";
import filters from "../../filters";

export default {
  name: "Login",
  components: { ForgotPassword },
  data() {
    return {
      forgotDialog: false,
      email: "",
      password: "",
      show: false,
      rules: {
        required: (value) => !!value || this.$t("common.required_field"),
        email: (text) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          /[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}/.test(text) ||
          this.$t("login.errors.email_not_entered"),
        min: (v) => v.length >= 6 || "Min 6 characters",
      },
      loading: false,
      feedback: null,
    };
  },
  created() {
    const params =
      Object.keys(this.$route.params).length !== 0 ? this.$route.params : this.$route.query;
    this.email = params.email || "";
    console.log("login created");
    console.log(params);

    if (params.lang) {
      filters.updateLocale(params.lang);
      this.$i18n.locale = params.lang;
      localStorage.setItem("languageSelect", params.lang);
    }
    if (!this.company && params.company) {
      this.$store.dispatch("getCompany", params.company);
    }
  },
  computed: {
    company() {
      return this.$store.state.trip.company;
    },
    companyImage() {
      if (!this.company) return null;
      return this.$store.getters.getImageUrlById(this.company.id);
    },
  },
  methods: {
    forgotPassword(resetEmail) {
      console.log(resetEmail);
      login
        .sendPasswordResetEmail(resetEmail)
        .then(() => {
          this.$refs.forgot_dialog.emailSent();
        })
        .catch((error) => {
          console.log(error);
          this.$refs.forgot_dialog.emailSent();
        });
    },
    emailSignIn() {
      if (!this.$refs.form.validate()) {
        console.log("errors exist");
        return;
      }
      console.log(this.email, this.password);
      this.loading = true;
      this.feedback = null;
      login
        .emailSignIn(this.email, this.password)
        .then(() => {
          console.log("succes in signin in");
          this.loading = false;
        })
        .catch((error) => {
          console.log("loginError", error);
          this.loading = false;
          // Handle Errors here.
          const { code: errorCode, message: errorMessage } = error;

          switch (errorCode) {
            case "auth/email-already-in-use":
              this.feedback = this.$t("login.errors.email_in_use");
              break;
            case "auth/invalid-email":
              this.feedback = this.$t("login.errors.email_not_entered");
              break;

            case "auth/weak-password":
              this.feedback = this.$t("login.password_hint");
              break;
            case "auth/user-not-found":
            case "auth/wrong-password":
              this.feedback = this.$t("login.errors.wrong_username_password");
              break;
            default:
              this.feedback = error.message;
              break;
          }
          console.log("myError", errorCode, errorMessage);
        });
    },
  },
  watch: {
    company(company, oldValue) {
      if (company && company.primary_color) {
        this.$vuetify.theme.themes.light.primary = company.primary_color;
        this.$vuetify.theme.themes.light.secondary = company.secondary_color;
      }
    },
  },
};
</script>

<style scoped>
.circle {
  width: 200px;
  height: 200px;
  background: white;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
</style>
