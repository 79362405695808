/* eslint-disable no-param-reassign */
import Vue from 'vue';
import Vuex from 'vuex';
import database from '../firebase/database';
import storage from '../firebase/storage';
import firestore from '../firebase/firestore';
import trip from "./trip";
import i18n from "../i18n";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    firUser: null,
    currentUser: null,
    imageUrls: {},
    users: {},
    userTrips: null,

  },
  mutations: {
    firUserUpdated: (state, user) => { state.firUser = user; },
    currentUserUpdated: (state, currentUser) => {
      state.currentUser = currentUser;
      if (currentUser.web_lang && i18n.locale !== currentUser.web_lang) {
        console.log("changing locale to ", currentUser.web_lang);
        i18n.locale = currentUser.web_lang;
      }
    },
    updateCollectionDocument: (state, payload) => {
      Vue.set(state[payload.collection], payload.document.id, payload.document);
    },
    deleteCollectionDocument: (state, payload) => {
      Vue.delete(state[payload.collection], payload.documentId);
    },
    tripsUpdated: (state, trips) => { state.userTrips = trips; },
    newUrl: (state, url) => { Vue.set(state.imageUrls, url.id, url.url); },
    logOut: (state) => {
      state.firUser = null;
      state.currentUser = null;
    },
  },
  getters: {
    getImageUrlById: (state) => (id) => state.imageUrls[id],
  },
  actions: {
    logOut: (context, user) => {
      database.stopObservingUser(user.uid);
      database.stopTripObservers();
      firestore.unsubscribeTripListeners();
      context.commit("deleteTrip");
      context.commit('logOut');
    },
    userLogedIn: (context, user) => {
      database.observeCurrentUser(context, user);
    },
    getImageUrl: (context, object) => {
      storage.getDownloadURL(object.path).then((url) => {
        context.commit('newUrl', { id: object.id, url });
      }).catch((error) => {
        console.log(error.message);
      });
    },
    getTrips: (context) => {
      firestore.getTrips(context, context.state.firUser.uid);
    },
  },
  modules: {
    trip,
  },
});
