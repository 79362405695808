<template>
  <v-app>
    <Navbar>
      <v-app-bar-nav-icon
        v-show="trip"
        class="hidden-lg-and-up"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
    </Navbar>
    <v-navigation-drawer
      class="nav-style"
      height="265px"
      style="background-color: var(--v-primary-base); overflow-y: hidden"
      v-if="trip"
      v-model="drawer"
      app
      dark
      clipped
      floating
      :permanent="!$vuetify.breakpoint.mobile"
      :expand-on-hover="!$vuetify.breakpoint.mobile"
    >
      <TripDrawer />
    </v-navigation-drawer>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import "@/firebase/init";
import Navbar from "./components/Navigation/Navbar";
import TripDrawer from "./components/Navigation/TripDrawer";

export default {
  name: "App",
  components: {
    Navbar,
    TripDrawer,
  },
  data: () => ({
    drawer: null,
  }),
  computed: {
    trip() {
      return this.$store.getters.currentTripId;
    },
  },
};
</script>

<style scoped>
.nav-style {
  margin-left: 12px;
  margin-top: 20px;
  border-radius: 30px;
}
</style>
