import firebase from 'firebase/app';
import 'firebase/database';

const database = firebase.database();
const usersRef = database.ref('users/');

const tripObservers = [];

const observeCurrentUser = (context, user) => {
  const userRef = usersRef.child(user.uid);
  userRef.on('value', (snapshot) => {
    const docUser = { ...snapshot.val(), id: user.uid };
    context.commit('currentUserUpdated', docUser);
    context.commit('updateTripCollectionDocument', { collection: 'tripUsers', document: docUser });
    context.dispatch('getImageUrl', { path: `profile_pics/${user.uid}/profilePic.jpg`, id: user.uid });
  });
};

const observeTripUsers = (context, userIds) => {
  const { users } = context.state;
  const currentUserid = context.rootState.firUser.uid;
  Object.keys(userIds).forEach((userId) => {
    if (users[userId]) {
      console.log(`not observing ${userId} already observing`);
      return;
    }
    if (userId === currentUserid) {
      console.log(`not observing currentUser, it has separate observer`);
      return;
    }

    const userRef = usersRef.child(userId);
    tripObservers.push(userRef.toString());
    userRef.on('value', (snapshot) => {
      const document = { ...snapshot.val(), id: userId };

      context.commit('updateTripCollectionDocument', { collection: 'tripUsers', document });
      if (document.profile_pic) {
        context.dispatch('getImageUrl', { path: `profile_pics/${userId}/profilePic.jpg`, id: userId });
      }
    });
  });
};

const getUsers = (context, userIds) => {
  Object.keys(userIds).forEach((userId) => {
    const userRef = usersRef.child(userId);

    userRef.once('value').then((snapshot) => {
      const document = { ...snapshot.val(), id: userId };
      context.commit('updateCollectionDocument', { collection: 'users', document });
      if (document.profile_pic) {
        context.dispatch('getImageUrl', { path: `profile_pics/${userId}/profilePic.jpg`, id: userId });
      }
    });
  });
};

const pushNewContact = (tripId, contact) => {
  database.ref(`groups/${tripId}/contacts`).push(contact);
};
const updateContect = (tripId, contactId, contact) => {
  database.ref(`groups/${tripId}/contacts/${contactId}`).update(contact);
};
const deleteContact = (tripId, contactId) => {
  database.ref(`groups/${tripId}/contacts/${contactId}`).remove();
};
const addFile = (tripId, ref, id) => {
  console.log(`adding file at groups/${tripId}/files`);
  const databaseRef = database.ref(`groups/${tripId}/files`).child(id);
  console.log(ref.toString());
  databaseRef.set(ref);
};
const deleteFile = (tripId, fileId) => {
  database.ref(`groups/${tripId}/files/${fileId}`).remove();
};

const observePath = (context, tripId, collection) => {
  const ref = database.ref(`groups/${tripId}/${collection}`);
  ref.on("child_added", (snapshot) => {
    const document = { ...snapshot.val(), id: snapshot.key };
    context.commit('updateTripCollectionDocument', { collection, document });
  });
  ref.on("child_changed", (snapshot) => {
    const document = { ...snapshot.val(), id: snapshot.key };
    context.commit('updateTripCollectionDocument', { collection, document });
  });
  ref.on("child_removed", (snapshot) => {
    context.commit('deleteTripCollectionDocument', { collection, documentId: snapshot.key });
  });

  tripObservers.push(ref.toString());
};

// const observeFiles = (context, tripId) => {
//     let collection = "files"
//     let ref = database.ref(`groups/${tripId}/${collection}`)
//     ref.on("child_added", snapshot => {
//         let document = { value: snapshot.val(), id: snapshot.key }
//         context.commit('updateDatabaseTripChild', { collection, document })
//     })
//     ref.on("child_changed", snapshot => {
//         let document = { value: snapshot.val(), id: snapshot.key }
//         context.commit('updateDatabaseTripChild', { collection, document })
//     })
//     ref.on("child_removed", snapshot => {
//         context.commit('deleteCollectionDocument', { collection, documentId: snapshot.key })
//     })

//     tripObservers.push(ref.toString())
// }

// const observeContacts = (context, tripId) => {
//     observePath(context, tripId, 'contacts')
// }

const observeTrip = (context, tripId) => {
  // observeContacts(context, tripId)
  // observeFiles(context, tripId)
};

const createNewUser = ((userId, name, email, dob) => usersRef.child(userId).set({
  name,
  email,
  phone: '',
  platform: "Web",
  dob,
  accept_terms: new Date().getTime(),
  account_type: 'free',
  other: '',
}));

const stopObservingUser = (userId) => {
  console.log(`stop observing user ${userId}`);
  usersRef.child(userId).off();
};

const updateProfile = (userId, user) => usersRef.child(userId).update(user);

const updateUserGroups = (userId, group, value) => {
  const ref = usersRef.child(`${userId}/groups/${group}`);
  console.log(ref.toString());
  return ref.set(value);
};

const stopTripObservers = () => {
  tripObservers.forEach((ref) => {
    console.log(`removing observer at : ${ref}`);
    database.refFromURL(ref).off();
  });
};
const childByAutoId = () => database.ref().push().key;

export default {
  observeCurrentUser,
  observeTripUsers,
  getUsers,
  updateProfile,
  stopObservingUser,
  stopTripObservers,
  createNewUser,
  updateUserGroups,
  observeTrip,
  pushNewContact,
  updateContect,
  deleteContact,
  deleteFile,
  addFile,
  childByAutoId,
};
