<template>
  <v-card>
    <v-card-text>
      <div class="d-flex justify-space-between align-center">
        <div class="d-flex align-center">
          <v-avatar class="ml-2" size="36px">
            <img alt="Avatar" :src="poll.userPic" />
          </v-avatar>
          <h4 class="ml-2">{{ poll.userName }}</h4>
          <!-- <strong class="ml-2" v-html="post.userName">{{post.userName}}</strong> -->
        </div>
        <div>
          <span>{{ poll.date.toDate() | timeDistanceFromNow }}</span>
          <v-menu v-if="canDelete" offset-y>
            <!-- for now canDelete here as menu has only one option -->
            <template v-slot:activator="{ on }">
              <v-btn text icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="canDelete" @click="deletePost()">
                <v-list-item-title>{{ $t("common.delete") }} </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <!-- <p class="mt-2 mb-0">{{poll.question}}</p> -->
      poll below here
      <VuePoll
        class="mt-4"
        :question="poll.question"
        :answers="answers"
        @addvote="addVote"
        :finalResults="hasVoted"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import firestore from "@/firebase/firestore";
import VuePoll from "./Poll";

export default {
  props: ["poll"],
  components: { VuePoll },
  data: () => ({
    answer: null,
  }),
  methods: {
    deletePoll() {
      const tripId = this.$store.getters.currentTripId;
      if (this.post.scheduled_date) {
        firestore.deleteDocument(tripId, "scheduled", this.$props.post.id);
      } else {
        firestore.writeTrackedDocument(tripId, "feed", {}, this.$props.poll.id, true);
      }
    },
    addVote(obj) {
      firestore.writeTrackedDocument(
        this.$store.getters.currentTripId,
        "polls",
        {
          [`votes.${this.userId}`]: obj.value,
        },
        this.$props.poll.id,
      );
    },

    // agoTime(date) {
    //   const now = new Date();
    //   return `${this.$t("common.time.ago", {
    //     time: this.$options.filters.timeDistanceFromNow(date, now),
    //   })}`;
    // },
  },
  computed: {
    canDelete() {
      const meId = this.userId;
      return this.$props.poll.sender_id === meId || this.$store.state.trip.trip.user_ids[meId];
    },
    hasVoted() {
      const votedUsers = Object.keys(this.$props.poll.votes || {});
      return votedUsers.includes(this.userId);
    },
    answers() {
      const { poll } = this.$props;
      const votes = Object.values(poll.votes || {});
      const options = poll.options.map((option, index) => ({
        value: index,
        text: option,
        votes: votes.filter((v) => v === index).length,
        selected: (poll.votes || {})[this.userId] === index,
      }));

      return options;
    },
    userId() {
      return this.$store.state.firUser.uid;
    },
  },
};
</script>
