<template>
  <v-card>
    <v-card-text>
      <div class="d-flex align-center flex-row">
        <v-avatar>
          <v-img :src="userProfilePic"></v-img>
        </v-avatar>
        <v-divider vertical inset class="ml-4"></v-divider>
        <v-textarea
          :rows="1"
          auto-grow
          clearable
          rounded
          :label="$t('common.shareGroupLabel')"
          v-model="postText"
        ></v-textarea>
      </div>
      <v-row v-if="images">
        <v-col v-for="(image, i) in images" :key="i" sm="4" md="3">
          <v-card>
            <v-img height="100px" :src="image">
              <div class="d-flex flex-row-reverse ma-1">
                <v-btn small icon class="white" color="primary" @click="removeImage(i)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </v-img>
          </v-card>
        </v-col>
        <v-col cols="3">
          <input
            type="file"
            style="display: none"
            ref="image"
            accept="image/png, image/jpeg, image/jpg"
            @change="onImagePicked"
          />
          <v-card
            @click="$refs.image.click()"
            height="100px"
            class="d-flex justify-center align-center"
          >
            <v-icon color="primary" size="60">mdi-plus</v-icon>
          </v-card>
        </v-col>
      </v-row>

      <v-card v-else-if="pdf" class="py-auto text-center pt-2" max-width="200px">
        <v-img
          class="mx-auto"
          width="100px"
          height="100px"
          contain
          :src="require('@/assets/pdf.png')"
        ></v-img>
        <h4 class="mt-2 mx-auto py-2 grey lighten-2">{{ pdf.name }}</h4>
      </v-card>
    </v-card-text>
    <v-card-actions class="grey lighten-2">
      <div class="d-flex flex-wrap flex-grow-1">
        <input
          type="file"
          multiple
          style="display: none"
          ref="images"
          accept="image/*"
          @change="onImagesPicked"
        />
        <v-btn tile text color="primary" @click="$refs.images.click()">
          <v-icon left>mdi-image</v-icon>{{ $t("common.image") }}
        </v-btn>
        <input
          type="file"
          multiple
          style="display: none"
          ref="pdf"
          accept="application/pdf"
          @change="onPDFPicked"
        />
        <v-btn tile text color="primary" @click="$refs.pdf.click()">
          <v-icon left>mdi-file-pdf-box</v-icon>
          {{ $t("common.pdf") }}
        </v-btn>
        <NewPollDialog />
        <v-spacer></v-spacer>
      </div>
      <v-btn :disabled="!canPost" :loading="sending" @click="post()" class="primary">
        {{ $t("common.post") }}
        <v-icon right>mdi-send</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import firestore from "@/firebase/firestore";
import storage from "@/firebase/storage";
import resizeImage from "@/plugins/image-resize.js";
import NewPollDialog from "./NewPollDialog";

export default {
  components: { NewPollDialog },
  data: () => ({
    postText: "",
    sending: false,
    images: null,
    imageFiles: null,
    resizedImages: null,

    pdf: null,
    newPollDialog: false,
  }),
  methods: {
    onImagesPicked(e) {
      const { files } = e.target;

      if (!files || files.length === 0) return;

      this.clearFiles();
      this.imageFiles = Array.prototype.slice.call(files);
      this.images = [];
      this.resizedImages = [];
      console.log("onImagesPicked", files.length);
      files.forEach((file) => {
        this.addImage(file);
      });
    },
    onImagePicked(e) {
      const { files } = e.target;
      if (!files || files.length === 0) return;
      const file = files[0];
      this.addImage(file);
      this.imageFiles.push(file);
    },
    onPDFPicked(e) {
      const { files } = e.target;
      if (!files || files.length === 0) return;

      this.clearFiles();
      [this.pdf] = files;
    },

    addImage(file) {
      if (!file.type.match(/image.*/)) {
        console.log("is no image!");
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        // get file content
        const src = e.target.result;
        this.images.push(src);
      };
      reader.readAsDataURL(file);
      resizeImage({ file })
        .then((resizedImage) => {
          this.resizedImages.push(resizedImage);
        })
        .catch((err) => {
          console.error(err);
        });
      // END: preview resized
    },
    async post() {
      this.sending = true;

      const data = {
        sender_id: this.userId,
        text: this.postText,
        date: new Date(),
      };

      const tripId = this.$store.getters.currentTripId;

      const document = await firestore.writeTrackedDocument(tripId, "feed", data);
      await this.postFiles(document.id, tripId);
      this.sending = false;
    },
    async postFiles(id, tripId) {
      if (this.pdf) {
        const path = `groups/${tripId}/files/${id}-${this.pdf.name}`;
        await storage.uploadFile(this.pdf, path);
        const paths = {};
        paths[id] = path;
        await firestore.writeTrackedDocument(tripId, "feed", { files: paths }, id);
      } else if (this.imageFiles) {
        const promises = [];
        const paths = {};
        this.imageFiles.forEach((file, i) => {
          const imageName = `${id}_${i}`;
          const extension = this.imageFiles[i].name.split(".").pop();
          const path = `groups/${tripId}/images/${imageName}.${extension}`;
          promises.push(storage.uploadFile(this.resizedImages[i], path));

          paths[imageName] = path;
        });
        await Promise.all(promises);
        await firestore.writeTrackedDocument(tripId, "feed", { files: paths }, id);
      }

      this.sending = false;
      this.postText = "";
      this.clearFiles();
    },

    removeImage(i) {
      this.images.splice(i, 1);
      this.imageFiles.splice(i, 1);
      this.resizedImages.splice(i, 1);
    },

    clearFiles() {
      this.imageFiles = null;
      this.images = null;
      this.pdf = null;
      this.resizedImages = null;
    },
  },
  computed: {
    userId() {
      return this.$store.state.firUser.uid;
    },
    userProfilePic() {
      return this.$store.getters.getImageUrlById(this.userId);
    },
    canPost() {
      return this.postText !== "" || this.pdf || this.imageFiles;
    },
  },
};
</script>
