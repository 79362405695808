import Itinerary from "@/components/Trip/Itinerary/Itinerary";
// import Travelers    from "@/components/Users/Travelers";
// import Cities       from "@/components/CurrentTrip/Cities/Cities";
// import Surveys       from "@/components/CurrentTrip/Surveys/Surveys";
// import Contacts     from "@/components/CurrentTrip/Contacts";
// import Settings     from "@/components/CurrentTrip/Settings";
// import Training     from "@/components/CurrentTrip/Training";
import Feed from "@/components/Trip/Feed/Feed";
// import Files        from "@/components/CurrentTrip/Files/Files";
import Chat from "@/components/Trip/Chat/Chat";
import Trip from '../views/CurrentTrip';
import store from '../store/index';

export default {
  path: '/trip',
  name: 'Trip',
  props: true,
  component: Trip,
  children: [
    { path: '', component: Itinerary },
    { name: 'Itinerary', path: 'itinerary', component: Itinerary },
    // { path: 'travelers/:type', component: Travelers, props: true },
    // { path: 'cities', component: Cities },
    // { path: 'surveys', component: Surveys },
    // { path: 'contacts', component: Contacts },
    // { path: 'settings', component: Settings },
    // { path: 'training', component: Training },
    { path: 'feed', component: Feed },
    { path: 'chat', component: Chat },
    // { path: 'files', component: Files },

  ],
  // router.push({ path: 'foo', append: true })

  beforeEnter: ((to, from, next) => {
    console.log("befor enter trip");
    console.log(to);
    if (to.params.tripId) {
      console.log("going next with no prob");
      next();
    } else if (store.state.trip.trip) {
      console.log("reusing prev trip", store.state.trip.trip.id);
      next();
    } else {
      console.log("no trip, fall back!");
      next({ name: 'Trips' });
    }
  }),
};
