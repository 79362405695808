<template>
  <v-container>
    <v-card class="mx-auto text-center mt-9 pa-3" max-width="400">
      <v-icon color="primary" large>mdi-lock-open-outline</v-icon>

      <template v-if="!passwordChanged">
        <v-card-title>Reset Password</v-card-title>

        <v-card-subtitle class="pb-0">Enter your new password</v-card-subtitle>

        <v-card-text>
          <v-form v-model="validPassword">
            <v-text-field :value="email" :label="$t('common.email')" disabled></v-text-field>

            <v-text-field
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min, rules.pass]"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              :label="$t('login.newPassword')"
              hint="Minimum 8 characters containing at least an uppercase and a number"
              counter
              @click:append="show1 = !show1"
            ></v-text-field>
            <div class="red--text mt-4 body-1">{{ feedback }}</div>
            <!-- <div>Minimum 8 characters containing at least an uppercase and a number</div> -->
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :loading="loading" @click="resetPassword">Reset Password</v-btn>
        </v-card-actions>
      </template>
      <template v-else>
        <h2 class="mt-2">Password Changed</h2>
        <v-card-text>
          <v-icon class="my-3" x-large color="primary">mdi-checkbox-marked-circle</v-icon>
          <v-card-subtitle class="pb-0">Your password was succesfully changed</v-card-subtitle>
          <br />
          <a v-if="continueUrl" :href="continueUrl">Back to app</a>
        </v-card-text>
      </template>
    </v-card>
  </v-container>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";

export default {
  name: "ResetPassword",
  data() {
    return {
      email: "",
      password: "",
      show1: false,
      validPassword: false,
      feedback: null,
      loading: false,
      actionCode: null,
      continueUrl: null,
      passwordChanged: false,

      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        pass: (v) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          /^(?=.*[A-Z])(?=.*\d)[!-}]{8,}$/.test(v) || "Aat least an uppercase and a number",
      },
    };
  },
  methods: {
    resetPassword() {
      if (!this.validPassword) {
        this.feedback = "Please enter a valid password";
        return;
      }
      this.feedback = null;
      this.loading = true;
      console.log("confirming password reset");
      const auth = firebase.auth();
      auth
        .confirmPasswordReset(this.actionCode, this.password)
        .then((resp) => {
          console.log(resp);
          this.passwordChanged = true;
          if (this.continueUrl && this.continueUrl.includes("login")) {
            auth.signInWithEmailAndPassword(this.email, this.password);
          }
        })
        .catch((error) => {
          this.feedback = error.message;
        });
    },
    handleResetPassword(auth, actionCode, continueUrl, lang) {
      console.log(lang);
      console.log(continueUrl);

      // Verify the password reset code is valid.
      auth
        .verifyPasswordResetCode(actionCode)
        .then((email) => {
          this.email = email;
        })
        .catch((error) => {
          this.feedback = error.message;
        });
    },
  },
  created() {
    // Get the action to complete.
    const { mode } = this.$route.query;
    // Get the one-time code from the query parameter.
    this.actionCode = this.$route.query.oobCode;

    // (Optional) Get the continue URL from the query parameter if available.
    this.continueUrl = this.$route.query.continueUrl;
    // (Optional) Get the language code if available.
    const lang = this.$route.query.lang || "en";

    const auth = firebase.auth();

    // Handle the user management action.
    switch (mode) {
      case "resetPassword":
        // Display reset password handler and UI.
        this.handleResetPassword(auth, this.actionCode, this.continueUrl, lang);
        break;
      case "recoverEmail":
        // Display email recovery handler and UI.
        // handleRecoverEmail(auth, actionCode, lang);
        break;
      case "verifyEmail":
        // Display email verification handler and UI.
        // handleVerifyEmail(auth, actionCode, continueUrl, lang);
        break;
      default:
      // Error: invalid mode.
    }
  },
};
</script>
