import firebase from 'firebase/app';
import 'firebase/storage';

const getDownloadURL = ((path) => {
  // Create a reference with an initial file path and name
  const storage = firebase.storage();
  const pathReference = storage.ref(path);
  if (!path.includes('/')) {
    // console.log(`image is not real ${path}`)
    return new Promise((res) => {
      res(null);
    });
  }
  return pathReference.getDownloadURL();
});

const uploadFile = (file, path) => firebase
  .storage()
  .ref()
  .child(path).put(file);

export default {
  getDownloadURL,
  uploadFile,
};
