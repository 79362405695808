<template>
  <div>
    <div class="d-flex flex-row align-center" style="min-width: 150px">
      <v-btn @click="togglePlay" icon :dark="dark"
        ><v-icon>{{ isPlaying ? "mdi-pause" : "mdi-play" }}</v-icon></v-btn
      >
      <v-progress-linear
        :color="dark ? 'white' : 'primary'"
        class="mx-2"
        style="width: calc(100% - 50px)"
        :value="audioProgress"
      ></v-progress-linear>
    </div>
    <div class="ml-2 d-flex flex-row justify-space-between">
      <span class="text-caption">
        {{ audioDuration }}
      </span>
      <span class="text-caption">{{ date | toDate("LT") }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["audioSrc", "duration", "dark", "date"],
  data: () => ({
    player: null,
    isPlaying: false,
    audioProgress: 0,
  }),
  computed: {
    audioDuration() {
      const { duration } = this.$props;
      const minutes = Math.floor((duration / 60) % 60);
      const seconds = Math.floor(duration % 60);
      return `${minutes}:${`0${seconds}`.slice(-2)}`;
    },
  },
  methods: {
    togglePlay() {
      if (this.isPlaying) {
        this.a.pause();
      } else {
        this.a = new Audio(this.$props.audioSrc);
        this.a.play();
        this.updateProgress();
      }
      this.isPlaying = !this.isPlaying;
    },
    updateProgress() {
      const { duration } = this.$props;
      this.audioProgress = Math.min((100 * this.a.currentTime) / duration, 100);

      if (!this.a.paused && !this.a.ended) {
        setTimeout(() => {
          this.updateProgress();
        }, 200);
      } else if (this.a.ended) {
        this.isPlaying = false;
        this.audioProgress = 0;
      }
    },
  },
};
</script>
