<template>
  <v-card class="my-2 rounded-lg" color="#fefefe">
    <v-card-text>
      <v-row align="center">
        <v-col cols="2" md="1">
          <v-avatar color="secondary" size="36" class="ml-2 elevation-10" sm="4" icon>
            <v-icon color="white">
              {{ survey.type === "quiz" ? "mdi-map-marker-question" : "mdi-briefcase-check" }}
            </v-icon>
          </v-avatar>
        </v-col>
        <v-col cols="10" sm="10" md="5" lg="6" xl="7">
          <div :class="`text-h6 ${$vuetify.breakpoint.mobile ? 'justify-center' : ''}`">
            <b>{{ survey.name }}</b>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="5" xl="4">
          <div
            :class="`d-flex align-center
               ${$vuetify.breakpoint.mobile ? ' justify-center' : ' justify-end'}
              `"
          >
            <div class="primary--text">
              <b>
                <span class="mx-1">
                  <v-icon class="mr-1" small color="primary">mdi-clock</v-icon>
                  {{ $tc("surveys.noMinutes", responseTime) }}</span
                >
                <span class="ml-3"
                  >{{
                    $tc("surveys.surveys.noQuestions", survey.questions.length, {
                      noQuestions: survey.questions.length,
                    })
                  }}
                </span>
              </b>
            </div>
            <v-dialog persistent v-model="dialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="hasTakenSurvey"
                  color="primary"
                  class="white--text ml-3 px-9"
                  v-bind="attrs"
                  v-on="on"
                  >{{
                    hasTakenSurvey
                      ? $t(`surveys.${survey.type}_taken`)
                      : $t(`surveys.take_${survey.type}`)
                  }}
                </v-btn>
              </template>
              <Survey :survey="survey" @exit="dialog = false" />
            </v-dialog>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Survey from "../../Surveys/Survey";

export default {
  props: ["survey"],
  components: { Survey },
  data: () => ({
    dialog: false,
  }),
  computed: {
    type() {
      return this.$options.filters.capitalize(this.$props.survey.type);
    },
    responseTime() {
      return Math.ceil(15 * (this.$props.survey.questions.length / 60));
    },
    hasTakenSurvey() {
      const { responses } = this.$props.survey;
      if (!responses) return false;
      return responses[this.$store.state.firUser.uid] != null;
    },
  },
};
</script>
