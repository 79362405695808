<template>
  <v-container>
    <v-row class="pa-0 ma-0">
      <v-col cols="12" md="9" class="py-0">
        <div style="max-height: calc(100vh - 90px)" class="d-flex flex-column justify-center">
          <NewPost class="my-4 mx-auto" style="width: 100%; max-width: 600px" />
          <div
            ref="scroll"
            v-scroll.self="onScroll"
            class="flex-grow-1 px-1"
            style="overflow-y: auto"
          >
            <!-- this div is needed for detect frame with margins -->
            <div v-for="(post, key) in posts" :key="post.id" :id="post.id">
              <Poll
                v-if="post.collection == 'polls'"
                class="my-4 mx-auto"
                :key="key"
                :poll="post"
                style="max-width: 600px"
              />
              <Post v-else class="my-4 mx-auto" :key="key" :post="post" style="max-width: 600px" />
            </div>
          </div>
        </div>
      </v-col>
      <v-col class="d-none d-md-flex flex-column" md="3" style="overflow-y: auto; max-height: 83vh">
        <v-subheader class="primary--text text-h6">{{ $t("common.hostAndGuides") }}</v-subheader>
        <v-list>
          <v-list-item v-for="user in adminUsers" :key="user.id">
            <v-list-item-avatar>
              <v-img
                :src="$store.state.imageUrls[user.id] || require('@/assets/profile_pic_empty.png')"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-html="user.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-subheader class="primary--text text-h6">{{ $t("common.group") }}</v-subheader>
          <v-list-item v-for="user in groupUsers" :key="user.id">
            <v-list-item-avatar>
              <v-img
                :src="$store.state.imageUrls[user.id] || require('@/assets/profile_pic_empty.png')"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-html="user.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firestore from "@/firebase/firestore";
import NewPost from "./NewPost";
import Post from "./Post";
import Poll from "./PollPost";

export default {
  name: "Feed",
  components: { NewPost, Post, Poll },
  data() {
    return { timer: null };
  },
  // created() {
  //   this.$store.dispatch("observeFeed");
  // },
  computed: {
    users() {
      return this.$store.getters.tripUsers;
    },
    groupUsers() {
      const users = this.$store.state.trip.trip.user_ids;
      return this.users.filter((u) => !users[u.id]);
    },
    adminUsers() {
      const users = this.$store.state.trip.trip.user_ids;
      return this.users.filter((u) => users[u.id]);
    },
    posts() {
      return this.$store.getters.sortedPosts.map((post) => ({
        ...post,
        userName: this.$store.getters.getTripUserById(post.sender_id).name,
        userPic:
          this.$store.getters.getImageUrlById(post.sender_id) ||
          require("@/assets/profile_pic_empty.png"),
      }));
    },
  },
  mounted() {
    setTimeout(() => {
      this.onScroll({ target: this.$refs.scroll });
    }, 200);
  },
  methods: {
    onScroll(e) {
      // console.log("onScroll");
      const elementsInViewArray = this.posts
        .filter((post) => {
          if (post.collection !== "feed") return false;
          const postId = post.id;
          const el = document.getElementById(postId);
          return this.elementInViewport(el, e);
        })
        .map((p) => p.id);

      clearTimeout(this.timer);
      this.scheduleSeens(elementsInViewArray);
    },

    elementInViewport(el, e) {
      let top = el.offsetTop - e.target.offsetTop;
      const height = el.offsetHeight;
      const bottom = top + height;
      let ele = el;
      while (ele.offsetParent) {
        ele = ele.offsetParent;
        top += ele.offsetTop;
      }
      const offsetTop = e.target.scrollTop;
      const windowHeight = e.target.offsetHeight;

      return (
        top > offsetTop &&
        bottom > offsetTop &&
        top < offsetTop + windowHeight &&
        bottom <= offsetTop + windowHeight
      );
    },
    scheduleSeens(postIds) {
      this.timer = setTimeout(() => {
        const tripId = this.$store.getters.currentTripId;
        const meId = this.$store.state.firUser.uid;
        const posts = this.$store.state.trip.feed;
        // eslint-disable-next-line no-restricted-syntax
        for (const postId of postIds) {
          const seens = Object.keys(posts[postId].seens || {});
          if (!seens.includes(meId)) {
            firestore.writeTrackedDocument(
              tripId,
              "feed",
              {
                [`seens.${meId}`]: true,
              },
              postId,
            );
          }
        }
      }, 800);
    },
  },
};
</script>
