<template>
  <v-container class="mt-4">
    <v-card max-width="400" class="mx-auto">
      <v-form v-model="valid" lazy-validation ref="form">
        <v-container>
          <div class="d-flex flex-column">
            <div class="mx-auto" style="position: relative; width: 150px; height: 150px">
              <v-img
                style="border-radius: 50%"
                class="align-end text-right ma-4"
                :src="imageSrc || imageUrl"
                alt="avatar"
              >
                <input
                  type="file"
                  style="display: none"
                  ref="image"
                  accept="image/*"
                  @change="onFilePicked"
                />
              </v-img>
              <v-btn absolute bottom right dark icon class="primary" @click="$refs.image.click()">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>

            <v-text-field
              v-model="name"
              :rules="nameRules"
              :label="$t('common.name')"
              required
            ></v-text-field>
            <v-text-field
              v-model="phoneNumber"
              :rules="phoneRules"
              :label="$t('users.UserList.phoneHint')"
              @keyup="formatPhone"
              @blur="phoneFocus"
            ></v-text-field>
            <v-textarea rows="2" :label="$t('users.UserList.about')" v-model="about"></v-textarea>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="doneProfile">{{ $t("common.cancel") }}</v-btn>
            <v-btn color="primary" :loading="saving" @click="saveProfile">{{
              $t("common.save")
            }}</v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { parsePhoneNumberFromString, AsYouType } from "libphonenumber-js";
import database from "@/firebase/database";
import storage from "@/firebase/storage";

export default {
  name: "UserProfile",
  data: (vm) => ({
    valid: false,
    name: "",
    phoneNumber: "",
    about: "",

    file: null,
    imageSrc: null,
    saving: false,

    nameRules: [(v) => !!v || vm.$t("common.rules.name")],
    phoneRules: [
      (v) => {
        const phone = parsePhoneNumberFromString(v);
        const msg = vm.$t("users.UserList.phoneHint");
        if (phone) {
          return phone.isValid() || msg;
        }
        return v.length < 6 || msg;
      },
    ],
  }),
  methods: {
    formatPhone() {
      this.phoneNumber = new AsYouType().input(this.phoneNumber);
    },
    phoneFocus() {
      console.log("phone is  not focus");
      //   this.$refs.form.validate();
    },
    onFilePicked(e) {
      const { files } = e.target;
      if (files[0] !== undefined) {
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          [this.file] = files;
          this.imageSrc = fr.result;
        });
      }
    },
    doneProfile() {
      this.$router.go(-1);
    },
    saveProfile() {
      this.saving = true;
      const { firUser } = this.$store.state;
      if (firUser) {
        const data = {
          name: this.name,
          phone: this.phoneNumber,
          other: this.about,
        };

        database.updateProfile(firUser.uid, data);
        if (this.file) {
          console.log("should also save image!");
          storage.uploadFile(this.file, `profile_pics/${firUser.uid}/profilePic.jpg`).then(() => {
            const date = new Date();
            console.log("should update profile_pic to", date.getTime());
            database.updateProfile(firUser.uid, {
              profile_pic: date.getTime() + 0.1,
            });
            this.saving = false;
            this.doneProfile();
          });
        } else {
          this.saving = false;
          this.doneProfile();
        }
      }
    },
  },
  computed: {
    imageUrl() {
      const { firUser } = this.$store.state;
      if (!firUser) return require("@/assets/profile_pic_empty.png");
      return this.$store.state.imageUrls[firUser.uid] || require("@/assets/profile_pic_empty.png");
    },
  },
  created() {
    const user = this.$store.state.currentUser;
    console.log(user);
    this.name = user.name;
    this.phoneNumber = user.phone;
    this.about = user.other;
  },
};
</script>
