import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function loadMessages() {
  const context = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);

  const messages = context.keys().map((key) => ({ key, locale: key.match(/[a-z0-9-_]+/i)[0] }))
    .reduce(
      (msgs, { key, locale }) => ({
        ...msgs,
        [locale]: context(key),
      }),
      {},
    );
  return { context, messages };
}

const { context, messages } = loadMessages();
const i18n = new VueI18n({
  locale: localStorage.getItem("languageSelect") || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages,
});

// Hot updates
if (module.hot) {
  module.hot.accept(context.id, () => {
    const { messages: newMessages } = loadMessages();

    Object.keys(newMessages)
      .filter((locale) => messages[locale] !== newMessages[locale])
      .forEach((locale) => {
        messages[locale] = newMessages[locale];
        i18n.setLocaleMessage(locale, messages[locale]);
      });
  });
}
export default i18n;
