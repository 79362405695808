import firebase from 'firebase/app';
import 'firebase/database';
import notifications from '../plugins/tl-notifications';

const database = firebase.database();

const conversationRef = (tripId, conversationId) => database.ref(`/groups/${tripId}/chats/${conversationId}`);

const getLastMessage = (userId, ref) => ref.child(`seen_messages/${userId}`).once("value").then((snap) => snap.val());

const getNewMessages = (context, conversationId, ref, lastSeenMessage) => {
  const messagesRef = ref.child("messages");
  let query;

  if (lastSeenMessage) {
    query = messagesRef.orderByKey().startAt(lastSeenMessage);
  } else {
    query = messagesRef.orderByKey();
  }
  const userId = context.rootState.firUser.uid;
  query.on("child_added", (snapshot) => {
    const message = {
      id: snapshot.key,
      ...snapshot.val(),
    };
    message.isMine = message.sender === userId;

    const isNew = snapshot.key !== lastSeenMessage && !message.isMine;

    if (isNew) {
      notifications.newMessageNotification(context, conversationId, message);
    }

    context.commit('addMessage', {
      conversationId,
      message,
      isNew,
    });
  });
};

const loadNewMessages = async (context, tripId, conversationId) => {
  const ref = conversationRef(tripId, conversationId);
  const lastSeenMessage = await getLastMessage(context.rootState.firUser.uid, ref);
  context.commit("lastSeenMessage", { conversationId, lastSeenMessage });
  getNewMessages(context, conversationId, ref, lastSeenMessage);
};

const getMoreMeessages = (context, conversationId, lastSeenMessage) => {
  conversationRef(context.getters.currentTripId, conversationId)
    .child("messages")
    .orderByKey()
    .endAt(lastSeenMessage)
    .limitToLast(20)
    .once("value")
    .then((snapshot) => {
      const tempArray = [];
      const userId = context.rootState.firUser.uid;

      snapshot.forEach((item) => {
        if (item.key !== lastSeenMessage) {
          const message = {
            id: item.key,
            ...item.val(),
          };
          message.isMine = message.sender === userId;
          tempArray.push(message);
        }
      });
      tempArray.reverse();
      context.commit("oldMessagesAdded", { conversationId, messages: tempArray });
    });
};

const watchedMessages = (tripId, userId, conversationId, message) => {
  conversationRef(tripId, conversationId).update({
    [`seen_messages/${userId}`]: message,
  });
};

const sendMessage = (context, conversationId, message, messageId) => {
  const ref = conversationRef(context.getters.currentTripId, conversationId);

  const key = messageId || ref.push().key;
  context.commit("lastSeenMessage", { conversationId, lastSeenMessage: key });
  const updates = {};
  updates[`messages/${key})`] = message;
  updates[`seen_messages/${context.rootState.firUser.uid}`] = key;
  ref.update(updates);
};

export default {
  loadNewMessages,
  sendMessage,
  getMoreMeessages,
  watchedMessages,
};
