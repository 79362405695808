<template>
  <v-list-item @click="goToChat(chat.id)" :value="chat.id">
    <!-- {{chat.key}} -->
    <v-list-item-avatar>
      <v-img :src="imageUrl" :contain="chat.scale"></v-img>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title v-html="chat.name"></v-list-item-title>
      <v-list-item-subtitle v-html="chat.subtitle"></v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-list-item-action-text v-if="chat.time">
        {{ chat.time | smartDate }}
      </v-list-item-action-text>
      <div class="d-flex flex-row">
        <v-chip small v-show="pinned" class="grey lighten-2 pa-1">
          <v-icon color="grey darken-1" small>mdi-pin</v-icon>
        </v-chip>

        <v-chip v-if="chat.new > 0" class="ml-1 px-2" small color="red" text-color="white">{{
          chat.new
        }}</v-chip>
      </div>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  props: ["chat", "pinned"],

  computed: {
    imageUrl() {
      return (
        this.$store.getters.getImageUrlById(this.$props.chat.image) ||
        require("@/assets/chat_bck.jpeg")
      );
    },
  },
  methods: {
    goToChat() {
      this.$emit("goToChat", this.$props.chat.id);
    },
  },
};
</script>
