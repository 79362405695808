import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login/Login';
import SignUp from '../views/Login/SignUp';
import Home from '../views/Home';
import UserProfile from '../components/User/UserProfile';
import PrivacyPolicy from '../views/anonymous/PrivacyPolicy';
import TermsAndConditions from '../views/anonymous/TermsAndConditions';
import ResetPassword from '../views/Login/ResetPassword';
import JoinTrip from '../components/JoinTrip';
import store from '../store/index';
import trip from "./trip";
import DeleteAccount from "../views/Login/DeleteAccount";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
    },
  },
  trip,
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      isForLogin: true,
    },
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp,
    meta: {
      isForLogin: true,
    },
  },
  {
    path: '/trips',
    name: 'Trips',
    component: Home,
  },
  {
    path: '/profile',
    name: 'UserProfile',
    component: UserProfile,
  },
  {
    path: '/password_reset',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      isAnonymus: true,
    },
  },
  {
    path: '/join_trip',
    name: 'JoinTrip',
    component: JoinTrip,
  },
  {
    path: '/join-trip',
    name: 'JoinTrip',
    component: JoinTrip,
  },
  {
    path: '/privacy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: {
      isAnonymus: true,
    },
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions,
    meta: {
      isAnonymus: true,
    },
  },
  {
    path: '/delete_account',
    name: 'Delete Account',
    component: DeleteAccount,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  // check to see if route requires auth
  if (to.query && to.query.link) {
    const link = new URL(to.query.link);
    next(`${link.pathname}${link.search}`);
  } else if (to.matched.some((rec) => rec.meta.isAnonymus)) {
    console.log('is anonymus, going next');
    next();
  } else if (to.matched.some((rec) => rec.meta.isForLogin)) {
    console.log('isForLogin');
    const user = store.state.firUser;
    if (user) {
      console.log('redirecting to trips');
      next({ name: 'Trips' });
    } else {
      next();
    }
  } else {
    // check auth state of user
    const user = store.state.firUser;
    if (user) {
      next();
    } else {
      console.log(to);
      if (to.path !== "/") {
        console.log(`saving path ${to.path}`);
        router.startUrl = to.fullPath;
      }
      console.log(to.query);
      next({ name: 'Login', params: to.query });
    }
  }
});

export default router;
