<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        text
        small
        v-bind="attrs"
        v-on="on"
        class="pa-0 text-decoration-underline text--blue text-none"
      >
        {{ $t("itinerary.feedback.giveFeedBack") }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="font-weight-bold text-h5"> {{ $t("itinerary.feedback.leaveFeedback") }}</span>
      </v-card-title>
      <v-card-text v-if="posted" class="d-flex flex-column align-center mt-8">
        <v-icon color="primary" size="50">mdi-check-circle</v-icon>
        <span class="text-h6">
          {{ $t("itinerary.feedback.feedbackThanks") }}
        </span>
      </v-card-text>
      <v-card-text v-else>
        <div class="d-flex flex-column">
          <div class="mt-2">
            {{ $t("itinerary.feedback.feedbackDescription") }}
          </div>

          <div class="my-2">
            <v-row>
              <span class="font-weight-bold py-2 ml-3" style="font-size: 20px"
                >{{ $t("itinerary.feedback.feedBackRate") }}:</span
              >
              <v-rating v-model="rating" background-color="primary" color="primary"></v-rating>
            </v-row>
          </div>
          <div>
            <v-textarea
              solo
              :placeholder="
                $t('itinerary.feedback.feedbackPlaceholder', {
                  eventName: event.name,
                })
              "
              v-model="comment"
            ></v-textarea>
          </div>
          <span v-if="feedback" class="error--text">{{ feedback }}</span>
        </div>
      </v-card-text>
      <v-card-actions v-if="posted">
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="dialog = false">
          {{ $t("common.close") }}
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">
          {{ $t("common.cancel") }}
        </v-btn>
        <v-btn color="primary" @click="saveFeedback()">
          {{ $t("common.send") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import firestore from "@/firebase/firestore";

export default {
  name: "LeaveFeedBackCard",
  props: ["event"],
  data: () => ({
    dialog: false,
    rating: 0,
    comment: "",
    feedback: null,
    posted: false,
  }),
  methods: {
    saveFeedback() {
      if (this.rating === 0) {
        this.feedback = this.$t("itinerary.feedback.feedbackErrorRate");
        return;
      }

      const update = {};
      update[`ratings.${this.$store.state.firUser.uid}`] = {
        comment: this.comment,
        rating: this.rating,
      };
      firestore.writeTrackedDocument(
        this.$store.getters.currentTripId,
        this.$props.event.collection,
        update,
        this.$props.event.id,
      );
      this.posted = true;
    },
  },
};
</script>

<style scoped>
</style>
