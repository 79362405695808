<template>
  <div>
    <div
      class="message"
      v-for="(message, index) in formattedMessages"
      v-bind:key="index"
      :class="{ own: message.isMine }"
    >
      <div class="d-flex justify-center">
        <v-chip v-if="message.showDate" class="my-1" color="grey lighten-1" small>
          {{ message.messageDate | toDate("ll") }}
        </v-chip>
      </div>
      <div :class="[message.isMine ? 'd-flex justify-end' : 'd-flex justify-start']">
        <div>
          <v-avatar v-if="isGroupChat" class="mr-2">
            <v-img
              v-show="message.showAvatar"
              :src="message.imageUrl || require('@/assets/profile_pic_empty.png')"
            />
          </v-avatar>
          <div class="username" v-if="message.showAvatar && !message.isMine">
            {{ message.senderName }}
          </div>
        </div>
        <div style="margin-top: 5px"></div>
        <div class="d-flex flex-column" style="max-width: 50%">
          <div class="content">
            <div v-if="message.text" :class="{ 'text-h4': message.isEmojis }">
              <p
                class="mt-2 mb-0"
                style="white-space: pre-line; text-align: left; }"
                v-html="formatUrls(message.text)"
              ></p>
            </div>
            <Attachments
              v-if="message.imagePath || message.videoPath || message.audioPath"
              :message="message"
              @imageLoad="imageLoad"
            ></Attachments>
            <span v-if="!message.audioPath" class="caption">{{
              message.messageDate | toDate("LT")
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import Attachments from "./Attachments.vue";

export default {
  data() {
    return {};
  },
  props: ["messages", "isGroupChat"],
  components: { Attachments },
  computed: {
    username() {
      return this.$store.state.firUser.uid;
    },

    formattedMessages() {
      const { messages } = this.$props;
      if (messages == null) return [];
      let currentDate;
      return messages
        .filter((m) => m.deleted !== true)
        .map((message, i) => {
          const showAvatar = this.shouldShowAvatar(i) && !message.isMine;
          let showDate;

          const messageDate = new Date(message.timestamp);

          if (currentDate == null) {
            currentDate = messageDate;
            showDate = true;
          } else if (moment(currentDate).diff(moment(messageDate), "days") !== 0) {
            showDate = true;
            currentDate = messageDate;
          } else {
            showDate = false;
          }
          const emojiRegex =
            /^(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])+$/;

          return {
            id: message.id,
            isEmojis: emojiRegex.test(message.text),
            showDate,
            messageDate,
            senderName: this.$store.getters.tripUserShortName(message.sender),
            imageUrl: this.$store.getters.getImageUrlById(message.sender),
            isMine: message.isMine,
            showAvatar,
            text: message.text,
            imagePath: message.image_path,
            videoPath: message.video_path,
            audioPath: message.audio_path,
            audioDuration: message.audio_duration,
          };
        });
    },
  },
  methods: {
    formatUrls(text) {
      if (this.validURL(text) && this.post.link_metadata) {
        return "";
      }
      const URLMatcher =
        /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim;
      return (text || "").replace(
        URLMatcher,
        (match) => `<a href="${match}" target="_blank">${match}</a>`,
      );
    },
    validURL(str) {
      const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i",
      ); // fragment locator
      return !!pattern.test(str);
    },
    imageLoad() {
      this.$emit("imageLoad");
    },
    shouldShowAvatar(index) {
      const { messages } = this.$props;
      if (!this.$props.isGroupChat) return false;
      if (index + 1 < messages.length) {
        return messages[index + 1].sender !== messages[index].sender;
      }
      return true;
    },
  },
};
</script>

<style>
span.emoji {
  font-size: 20px;
  vertical-align: middle;
  line-height: 2;
}
</style>
