<template>
  <v-list-item :style="$vuetify.breakpoint.mobile ? 'max-width:60vw' : 'max-width:500px'">
    <v-list-item-avatar>
      <v-img :src="chat.image"></v-img>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title v-html="chat.name"></v-list-item-title>
      <v-list-item-subtitle v-html="chat.members"></v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
export default {
  props: ["conversationId"],
  methods: {
    selectedDetails() {
      if (this.conversation.name) {
        this.$emit("details");
      }
    },
  },
  computed: {
    conversation() {
      return this.$store.state.trip.chats[this.$props.conversationId];
    },
    chat() {
      const item = {};
      const meId = this.$store.state.firUser.uid;
      if (this.conversation.name) {
        item.name = this.conversation.name;
        if (this.conversation.image_path) {
          item.image = this.$store.getters.getImageUrlById(this.$props.conversationId);
        }
        item.members = Object.keys(this.conversation.users)
          .map((id) => this.$store.getters.tripUserShortName(id))
          .join(", ");
      } else {
        const partnerId = Object.keys(this.conversation.users).find((a) => a !== meId);
        item.name = this.$store.getters.getTripUserById(partnerId).name;
        item.image = this.$store.getters.getImageUrlById(partnerId);
      }

      if (!item.image) {
        item.image = require("@/assets/chat_bck.jpeg");
      }
      return item;
    },
  },
};
</script>
