<template>
  <v-container>
    <div>
      <v-img
        :src="require('@/assets/dotted_bck.png')"
        style="position: absolute; max-width: 50vw"
      ></v-img>
    </div>
    <div v-if="!trips">
      <div class="mx-auto" style="width: 100px; height: 100; margin-top: 40vh">
        <v-img contain :src="require('@/assets/loop.gif')"></v-img>
      </div>
    </div>
    <div v-else>
      <v-row class="pb-3 pt-3">
        <v-subheader class="text-h4 font-weight-bold black--text">{{
          $t("navbar.all_trips")
        }}</v-subheader>
      </v-row>
      <v-chip-group mandatory active-class="primary--text" v-model="tab">
        <v-chip v-for="category in categories" :key="category" active-class="primary">
          {{ $t(`login.Trips.${category}`) }}
        </v-chip>
      </v-chip-group>
      <v-row v-if="filteredTrips.length > 0">
        <v-col lg="4" xl="3" sm="6" cols="12" v-for="trip in filteredTrips" :key="trip.id">
          <v-card @click="goToTrip(trip.id)">
            <!--          :src="urls[trip.id] || require('@/assets/other.jpg')"-->
            <v-img
              :src="urls[trip.id] || require('@/assets/other.jpg')"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="400px"
            >
              <!--            <v-card-title v-text="trip.name"></v-card-title>-->

              <v-card class="mx-auto mt-0 mb-4 ml-3 mr-3" outlined color="rgb(255, 255, 255, 0.82)">
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="headline mb-1 font-weight-bold">
                      {{ trip.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ trip.description }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="mt-2">
                      <v-icon small color="primary">mdi-clock</v-icon>
                      <span class="text--primary font-weight-bold">
                        {{
                          $t("itinerary.dates", {
                            dates: `${$options.filters.zonedDate(trip.start_date, "l", "UTC")} -
                      ${$options.filters.zonedDate(trip.end_date, "l", "UTC")}`,
                          })
                        }}
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-card-actions class="mr-2">
                  <!-- <v-btn
                    outlined
                    text
                    small
                    width="50%"
                >
                  {{ $t('common.moreInfo') }}
                    width="50%"
                </v-btn> -->
                  <v-btn depressed block small color="primary" @click="goToTrip(trip.id)">
                    {{ $t("common.dashboard") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
      <v-card v-else>
        <v-card-title class="mx-auto justify-center">
          {{
            $t("login.Trips.noTrips", {
              current: $t(`login.Trips.${categories[tab]}`).toLowerCase(),
            })
          }}
        </v-card-title>
        <v-card-subtitle></v-card-subtitle>
      </v-card>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Trips",
  data() {
    return {
      tab: 0,
      categories: ["travelingNow", "upcoming", "past"],
    };
  },
  methods: {
    goToTrip(tripId) {
      console.log("should go to trip with id", tripId);
      this.$router.push({ name: "Itinerary", params: { tripId } });
    },
    showOptions() {
      console.log("should show options");
    },
  },
  computed: {
    trips() {
      return this.$store.state.userTrips;
    },
    canCreateTrips() {
      return Object.keys(this.$store.state.companies).length > 0;
    },
    filteredTrips() {
      const filtered = [];

      const now = new Date().getTime();
      if (!this.trips) {
        return [];
      }
      this.trips.forEach((trip) => {
        const startDate = trip.start_date.toDate().getTime();
        const endDate = trip.end_date.toDate().getTime();
        if (this.tab === 0) {
          if ((now >= startDate && now <= endDate) || startDate === 0 || endDate === 0) {
            filtered.push(trip);
          }
        } else if (this.tab === 1) {
          if (startDate >= now) {
            filtered.push(trip);
          }
        } else if (endDate <= now) {
          filtered.push(trip);
        }
      });
      return filtered;
    },
    urls() {
      return this.$store.state.imageUrls;
    },
  },

  created() {
    this.$store.dispatch("getTrips");
  },
  watch: {
    trips(newValue, oldValue) {
      if (newValue && !oldValue) {
        console.log("checking if currrentTrip");
        const currentTrip = localStorage.getItem("currentTrip");
        console.log(" currrentTrip is", currentTrip);
        if (currentTrip) {
          this.goToTrip(currentTrip);
        } else if (newValue.length === 1) {
          this.goToTrip(newValue[0].id);
        }
      }
    },
  },
};
</script>
