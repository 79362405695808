<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on }">
      <v-btn
        class="caption text-none primary--text"
        style="text-decoration: underline"
        text
        v-on="on"
        >{{ $t("login.forgotPassword.title") }}</v-btn
      >
    </template>

    <v-card>
      <v-card-title class="headline primary white--text" primary-title>{{
        $t("login.forgotPassword.title")
      }}</v-card-title>

      <v-card-text v-if="sent" class="mt-2">
        <h3>{{ $t("login.forgotPassword.mail_sent") }}</h3>
        <br />
        <p>{{ $t("login.forgotPassword.mail_sent_message", { recipient: sendEmail }) }}</p>
      </v-card-text>
      <v-card-text v-else class="mt-2">
        <h3>{{ $t("login.forgotPassword.message") }}</h3>
        <v-text-field label="Email" v-model="sendEmail" prepend-icon="mdi-email" />
        <p class="red--text" v-if="feedback">{{ feedback }}</p>
        {{ $t("login.forgotPassword.caption") }}
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="!sending" color="primary" text @click="dialog = false">{{
          $t(sent ? "common.close" : "common.cancel")
        }}</v-btn>
        <v-btn :loading="sending" color="primary" @click="sendResetEmail">{{
          $t("common.send")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["email"],
  data() {
    return {
      dialog: false,
      sendEmail: "",
      feedback: null,
      sending: false,
      sent: false,
    };
  },
  methods: {
    sendResetEmail() {
      if (this.sendEmail.isEmpty) {
        this.feedback = this.$t("common.error.validEmail");
        return;
      }
      if (!/[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}/.test(this.sendEmail)) {
        this.feedback = this.$t("common.error.validEmail");
        return;
      }
      this.feedback = null;
      this.sending = true;
      this.$emit("send-email", this.sendEmail);

      //   dialog = false;
    },
    emailSent() {
      this.sending = false;
      this.sent = true;
    },
  },
  watch: {
    // email(newEmail, email) {

    // }
    dialog(newValue, oldValue) {
      if (newValue === oldValue) return;
      if (newValue) {
        this.sendEmail = this.email;
      } else {
        this.sendEmail = null;
        this.feedback = null;
      }
    },
  },
};
</script>
