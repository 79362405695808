<template>
  <v-img v-if="message.imagePath && imgSrc" class="image" :src="imgSrc" />
  <vue-player
    class="video"
    v-else-if="message.videoPath && videoSrc && placeHolderSrc"
    :playsinline="false"
    :src="videoSrc"
    :poster="placeHolderSrc"
  ></vue-player>
  <div v-else-if="message.audioPath && audioSrc">
    <AudioPlayer
      :dark="message.isMine"
      :date="message.messageDate"
      :audioSrc="audioSrc"
      :duration="message.audioDuration"
    />
  </div>
  <v-skeleton-loader v-else width="200" height="200"></v-skeleton-loader>
</template>

<script>
import storage from "@/firebase/storage";
import vuePlayer from "@algoz098/vue-player";
import AudioPlayer from "./AudioPlayer";

export default {
  props: ["message"],
  components: { vuePlayer, AudioPlayer },
  data: () => ({
    imgSrc: null,
    videoSrc: null,
    audioSrc: null,
    placeHolderSrc: null,
    player: null,
  }),
  methods: {
    getDownloadURL(path, name, isImage) {
      storage.getDownloadURL(path).then((url) => {
        if (isImage) {
          const img = new Image();
          img.onload = () => {
            this[name] = url;
          };
          img.src = url;
        } else {
          this[name] =
            url || "https://media.tenor.com/images/d6cd5151c04765d1992edfde14483068/tenor.gif";
        }
      });
    },
    getAttachments(message) {
      if (message.videoPath) {
        this.getDownloadURL(message.videoPath, "videoSrc", false);
        this.getDownloadURL(`${message.videoPath}-thumbnail.jpg`, "placeHolderSrc", true).catch(
          (error) => {
            console.log(error);
            this.placeHolderSrc = require("@/assets/video.png");
          },
        );
      } else if (message.imagePath) {
        this.getDownloadURL(message.imagePath, "imgSrc", true);
      } else if (message.audioPath) {
        this.getDownloadURL(message.audioPath, "audioSrc", false);
      }
    },
  },
  mounted() {
    this.getAttachments(this.$props.message);
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    "$props.message"(message, oldVal) {
      if ((message && !oldVal) || (oldVal && oldVal.id !== message.id)) {
        this.getAttachments(message);
      }
    },
  },
};
</script>

<style scoped>
.image {
  margin: 10px 10px 10 10px;
  box-shadow: 0px 0px 34px -7px rgba(0, 0, 0, 0.75);
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  border-radius: 12px;
}

.video {
  margin: 10px 10px 10 10px;
  height: 200px;
  width: 200px;
  display: flex;
}

.message.own .image {
  margin-right: 0;
  margin-left: auto;
}
.image img {
  width: 150px;
}
@media (max-width: 480px) {
  .image {
    width: 120px;
  }
  .image img {
    width: 90px;
  }
}
</style>
