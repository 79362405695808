<template>
  <div class="d-flex flex-column">
    <div style="overflow-y: auto" class="flex-grow-1 overflow-y-auto">
      <div v-for="(comment, index) in previewComments" :key="index">
        <div class="my-3 d-flex flex-row">
          <v-avatar size="40px">
            <img alt="User" :src="comment.profilePic" />
          </v-avatar>
          <div class="mx-3" style="width: 100%">
            <div class="d-flex flex-row justify-space-between">
              <div class="text--darken-4">
                <b>{{ comment.senderName }}</b>
              </div>
              <div class="text-caption grey--text text--darken-1">
                {{ comment.date | toDate("lll") }}
              </div>
            </div>
            <div class="d-flex flex-row my-1 align-center">
              <div class="grey darken-3" style="width: 20px; height: 2px"></div>
              <span class="ml-3">{{ comment.text }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-divider class="my-3 elevation-1"></v-divider>
    <div class="d-flex flex-row flex-grow-0 flex-shrink-0">
      <v-avatar x-large>
        <img alt="User" :src="post.userPic" />
      </v-avatar>
      <v-text-field
        class="ml-4"
        v-model="newCommentText"
        :placeholder="$t('feed.comment')"
        filled
        rounded
        dense
        hide-details
        v-on:keyup.enter="postComment"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
import firestore from "@/firebase/firestore";

export default {
  props: ["post"],
  data: () => ({
    allComments: true,
    newCommentText: "",
  }),
  computed: {
    commentsCount() {
      const comments = this.$store.state.trip.postComments[this.$props.post.id];
      if (!comments) return 0;
      return comments.length;
    },
    previewComments() {
      const comments = this.$store.state.trip.postComments[this.$props.post.id];
      if (!comments) return [];
      console.log("aqui");
      const allComments = comments.map((c) => {
        console.log(this.$store.getters.getTripUserById(c.sender_id));

        return {
          profilePic:
            this.$store.state.imageUrls[c.sender_id] || require(`@/assets/profile_pic_empty.png`),
          text: c.comment,
          senderName: (this.$store.getters.getTripUserById(c.sender_id).name || "")
            .split(" ")
            .shift(),
          date: c.date,
        };
      });
      return this.allComments ? allComments : allComments.slice(0, Math.min(2, allComments.length));
    },
  },
  methods: {
    viewAllComments() {
      this.allComments = true;
    },
    postComment() {
      if (this.newCommentText !== "") {
        const comment = {
          comment: this.newCommentText,
          date: new Date(),
          sender_id: this.$store.state.firUser.uid,
        };

        const tripId = this.$store.getters.currentTripId;

        firestore.writePostComment(tripId, this.$props.post.id, comment);
        this.newCommentText = "";
      }
    },
  },
};
</script>
