<template>
  <!-- <div> -->
  <v-menu offset-y bottom>
    <template v-slot:activator="{ on }">
      <div
        class="grey lighten-1 d-flex flex-column justify-center pl-8 pr-2"
        style="border-radius: 24px 0 0 24px; height: 48px"
      >
        <v-btn class="text-none" text v-on="on">
          {{ name }}
          <v-icon>mdi-chevron-down</v-icon>
          <v-avatar right size="36" class="ml-3">
            <img :src="profilePicUrl" alt="avatar" />
          </v-avatar>
        </v-btn>
      </div>
    </template>

    <v-list>
      <v-list-item @click="editProfile">
        <v-list-item-title>{{ $t("navbar.edit_profile") }}</v-list-item-title>
      </v-list-item>

      <v-dialog v-model="languageDialog" width="300">
        <template v-slot:activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on">
            <v-list-item-title>{{ $t("navbar.language") }}</v-list-item-title>
          </v-list-item>
        </template>

        <v-card>
          <v-card-title class="primary white--text">
            {{ $t("navbar.language") }}
          </v-card-title>

          <v-card-text>
            <v-select
              v-model="locale"
              :items="locales"
              item-text="title"
              item-value="code"
            ></v-select>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="switchLocale(locale)">
              {{ $t("common.cancel") }}
            </v-btn>
            <v-btn color="primary" @click="switchLocale(locale)"> {{ $t("buttons.apply") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-list-item @click="logout">
        <v-list-item-title>{{ $t("navbar.logout") }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
  <!-- </div> -->
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import database from "@/firebase/database";
import filters from "../../filters";

const languageNames = {
  en: "English",
  ja: "日本語",
  es: "Español",
};
console.log("available locales are!!");
console.log(process.env.VUE_APP_I18N_SUPPORTED_LOCALE);
export default {
  props: ["user"],

  data() {
    return {
      languageDialog: false,
      locale: localStorage.getItem("languageSelect") || "enUS",
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(",").map((code) => ({
        code,
        title: languageNames[code],
      })),
    };
  },

  computed: {
    profilePicUrl() {
      const { firUser } = this.$store.state;
      if (firUser) {
        return (
          this.$store.state.imageUrls[firUser.uid] || require("@/assets/profile_pic_empty.png")
        );
      }
      return require("@/assets/profile_pic_empty.png");
    },
    name() {
      return ((this.$props.user || {}).name || "").split(" ").shift();
    },
  },
  methods: {
    async switchLocale(locale) {
      const { firUser } = this.$store.state;
      filters.updateLocale(locale);
      await database.updateProfile(firUser.uid, { web_lang: locale });
      this.$nextTick(() => {
        this.$i18n.locale = locale;
      });
      localStorage.setItem("languageSelect", locale);
      this.languageDialog = false;
      // location.reload();
    },
    changeLanguage() {
      this.languageDialog = true;
    },
    deletePrefs() {
      localStorage.removeItem("currentTrip");
      localStorage.removeItem("primaryColor");
    },
    logout() {
      this.$store.dispatch("logOut", this.$store.state.firUser);
      this.deletePrefs();
      firebase
        .auth()
        .signOut()
        .then(() => {
          console.log("user logged out, move to login");

          this.$router.push({ name: "Login" });
        });
    },
    editProfile() {
      console.log("editProfile");
      this.$router.push({ name: "UserProfile" });
    },
  },
};
</script>
